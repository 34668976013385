import { Modal } from 'office-ui-fabric-react/lib/Modal';
import SyncJobOutput from './SyncJobOutput';
import ActionHeader from '../../ActionHeader';
import { Icon } from 'office-ui-fabric-react/lib/Icon';

const SyncJobModal = (props) => {
    return <Modal isOpen={props.isOpen} isBlocking={false} onDismiss={props.closeCallback}>
        <div className="call-table-wrapper">
            <ActionHeader headerText="Sync Log" smallHeader={true}>
                <button className="btn btn-default no-focus" onClick={props.closeCallback}>
                    <Icon iconName="Cancel" className="fa-lg" />
                </button>
            </ActionHeader>
            <hr />

            <SyncJobOutput jobId={props.syncJobId} extendedInfo={true} />
        </div>
    </Modal>
}

export default SyncJobModal;