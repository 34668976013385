import React, { Component } from 'react';
import { getErrMsg, cmsDeleteAPIKeyPromise, cmsGetApiKeysPromise } from '../CallMSAPI.js';
import ExpandingTable from '../ExpandingReactTable';
import EditAPIRolesForm from "./EditAPIRolesForm";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { renderLocalFormattedDate } from '../utils/dateFormatter.js';

class APIKeyReactTable extends Component { 
    constructor(props) {
        super(props);

        this.state = {
            // Used by table
            filters: [],
            searchText: []
        }

        this.tableFilter = this.tableFilter.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    tableFilter(filter, onChange, type) {
        var self = this;
        var opts = null;

        return (
            <input
                onChange={event => {
                    let temp =[];
                    temp[type] = event.currentTarget.value;
                    self.setState({searchText:  temp});
                }}
                style={{ width: "100%" }}
                value={self.state.searchText[type]}
            />
        );
    }

    fetchData(filterValues, searchText, pageSize, page, sortSettings) {
        var self = this;

        var apiParams = {
            accountId: self.props.account.Id,
            objectType: 'apikeys'
        };

        if (pageSize) {
            apiParams['pageSize'] = pageSize;
        }

        if (page) {
            apiParams['currentPage'] = page;
        }

        if(sortSettings) {
            apiParams['SortBy'] = sortSettings.Column;
            apiParams['SortDirection'] = sortSettings.SortDirection;
        }

        if(searchText){
            if(searchText['name']) {
                apiParams['searchText'] = searchText['name'];
            }
        }

        return cmsGetApiKeysPromise(apiParams);
    }

    deleteApiKey(event, data) {
        var self = this;
        event.preventDefault();
        if (window.confirm("Are you sure you want to delete the API Key '" + data.Name + "'?")) {
            cmsDeleteAPIKeyPromise(
                self.props.account.Id,
                data.Id
            ).then(function () {
                toast.success("API key deleted");
            }, function (err) {
                toast.error("Unable to delete API key: " + getErrMsg(err));
            }).finally(function () {
                // Go up the chain in case other things rely on this refresh
                self.props.apiUpdateTrigger && self.props.apiUpdateTrigger();
            })
        }
    }

    render() {
        var self = this;

        return (
            <ExpandingTable
                columns={[
                    {
                        id: "name",
                        Header: "Name",
                        accessor: function (d) {
                            return d.Name;
                        },
                        colWidth: 200,
                        sortable: true,
                        Filter: ({ filter, onChange }) => self.tableFilter(filter, onChange, "name")
                    },
                    {
                        id: "roles",
                        Header: "Roles",
                        accessor: function (d) {
                            return (
                                <span title={d.RoleNames.join(", ")}>{d.RoleNames.join(", ")}</span>
                            );
                        },
                        filterable: false
                    },
                    {
                        id: "createdon",
                        Header: "Created On",
                        accessor: d => renderLocalFormattedDate(d.CreatedOn),
                        sortable: false,
                        filterable: false,
                        colWidth: 150
                    },
                    {
                        id: "delete",
                        Header: "",
                        className: "text-right",
                        colWidth: 50,
                        accessor: function (d) {
                            return (
                                <button onClick={(e) => self.deleteApiKey(e, d)} className="btn btn-xs btn-default">
                                    <i className="fa-solid fa-trash"></i>
                                </button>
                            );
                        },
                        filterable: false
                    }
                ]
                }
                sortBy={{
                    Column: 'name',
                    SortDirection: 'Descending',
                }}
                fetchData={self.fetchData}
                update={self.props.lastUpdate}
                loadingText={"Loading Data..."}
                noDataText={"You don't have any API Keys."}
                showPagination={true}
                pageSizeOptions={[10, 20, 50]}
                className="-striped -highlight"
                SubComponent={row => {
                    return (
                        <EditAPIRolesForm
                            closeForm={() => self.closeAllRows()}
                            apiKey={row.original}
                            closeButtonText={"Close"}
                            submitButtonText={"Save"}
                        />
                    );
                }}
                filters={self.state.filters}
                searchText={self.state.searchText}
            />
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(APIKeyReactTable);
