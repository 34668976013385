import React, { Component } from 'react';
import { Form, Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import { DocumentList } from '../CallMSUIHelpers';
import PBXTrunkServiceForm from './PBXTrunkServiceForm';
import { AllowedIPs } from './ServiceFormHelpers';
import { SubmitButton } from '../FormHelpers';
import { cmsStartExternalServiceSync } from '../CallMSAPI.js';
import { isSystemOwner } from '../CallMSUIHelpers.js';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

var _ = require('lodash');

class PBXServiceForm extends Component {

    getFormDefaults = ($this) => {
        return $this.getPBXFormDefaults(false);
    }

    renderDocuments = () => {
        if (this.props.pbxTemplate && this.props.pbxTemplate.Documents && this.props.pbxTemplate.Documents.length) {
            return (
                <>
                    <DocumentList
                        heading="Documents"
                        documents={this.props.pbxTemplate.Documents}
                    />
                    <hr />
                </>
            );
        }
        return null;
    }

    renderWarning = () => {
        if (!this.props.pbxTemplate && this.props.currentService) {
            return <div className="alert alert-warning">This service references a template that is no longer available.</div>;
        }
        return null;
    }

    render() {
        var self = this;
        return (
            <PBXTrunkServiceForm
                {...this.props}
                render={($this) => {

                    return (
                        <>
                            {this.renderWarning()}
                            {this.renderDocuments()}
                            <Formik
                                // Required so updates from getFormDefaults get propagated down to form
                                enableReinitialize={true}
                                initialValues={self.getFormDefaults($this)}
                                validate={$this.validatePBXValues}
                                onSubmit={(originalValues, { setSubmitting, setErrors, resetForm }) => {

                                    var values = _.cloneDeep(originalValues);
                                    if (values['StateId']) {
                                        values['CountryId'] = '';
                                    }

                                    if (!self.props.skipTeamsTrueTransferCheck) {
                                        $this.displayMisconfigurationWarnings(values);
                                    }

                                    values = $this.handleSubmitPBXValues(false, values);

                                    self.props.saveCallback(values, function (doReset, data, startSync) {
                                        setSubmitting(false);

                                        // Update full service, e.g. to get new SBC
                                        self.props.updateFullServiceTrigger && self.props.updateFullServiceTrigger();

                                        // Also update general service list, e.g. to get new Names
                                        $this.props.refetchServices();

                                        // reset form
                                        if (doReset) {
                                            resetForm && resetForm(self.getFormDefaults($this));
                                        }

                                        if (startSync) {

                                            if (self.props.skipAutoSync) {
                                                //This section has been changed, to let the wizard know if a pbx sync has been skipped or not
                                                self.props.skipSyncCallback && self.props.skipSyncCallback(true, data);
                                            } else {
                                                cmsStartExternalServiceSync(
                                                    $this.props.account.Id, data
                                                );
                                            }
                                        }

                                        self.props.closeCallback && self.props.closeCallback();
                                    }, function (error) {
                                        setSubmitting(false);
                                        toast.error(error);
                                    });

                                }}
                            >

                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue
                                    /* and other goodies */
                                }) => {
                                    var FormHidden = [];

                                    FormHidden.push(
                                        <Field key="AccountPBXTemplateId" type="hidden" name="AccountPBXTemplateId" value={values['AccountPBXTemplateId']} />
                                    );

                                    var FormKeys = $this.getFormKeys(values);

                                    // Only do general fields here...
                                    // Those with a specific viewgroup should be handled elsewhere.
                                    var NameCountry = _.filter(FormKeys, function (e) { return e.Group === 'NameCountry' });
                                    var TopKeys = _.filter(FormKeys, function (e) { return e.Group === 'AuthSection' });
                                    var PolicyKeys = _.filter(FormKeys, function (e) { return e.Group === 'CallingPolicy' });
                                    var GeneralKeys = _.filter(FormKeys, function (e) { return !e.Group });

                                    var NameCountryFormInputs = $this.formKeysToComponents(values, errors, touched, setFieldValue, NameCountry);
                                    var TopFormInputs = $this.formKeysToComponents(values, errors, touched, setFieldValue, TopKeys);
                                    var PolicyFormInputs = $this.formKeysToComponents(values, errors, touched, setFieldValue, PolicyKeys);
                                    var FormInputs = $this.formKeysToComponents(values, errors, touched, setFieldValue, GeneralKeys);

                                    var SBCModeDialog = $this.getGlobalPBXUpgradeDialog(values, setFieldValue);

                                    var closeButton = null;
                                    if (self.props.closeCallback
                                        && (!self.props.restrictServiceTabs || (self.props.restrictServiceTabs && self.props.currentServices && self.props.currentServices.length >= 1))) {
                                        closeButton = <button
                                            onClick={self.props.closeCallback}
                                            className="btn btn-default btn--service-form"
                                            disabled={isSubmitting || values.IsUIDisabled}>{self.props.closeText}</button>;
                                    }

                                    var revealButton = null;
                                    var template = self.props.pbxTemplate;
                                    if (template && template.hasOwnProperty('AllowReveal') && template.AllowReveal) {
                                        revealButton = $this.getHiddenFieldToggleButton(isSubmitting, values);
                                    }

                                    var SBCRender = null;
                                    var systemOwner = isSystemOwner(this.props.baseAccount.roles);
                                    var isAllowReadSBC = template && template.hasOwnProperty('AllowReadSBCAllocation') && template.AllowReadSBCAllocation;
                                    var isTemplateOwner = template && template.hasOwnProperty('ForceAllowReadSBCAllocation') && template.ForceAllowReadSBCAllocation;
                                    if (isAllowReadSBC || systemOwner || isTemplateOwner) {
                                        SBCRender = $this.getSBCs();
                                        if (SBCRender !== null) {
                                            if (isAllowReadSBC) {
                                                SBCRender = <>{SBCRender}<hr /> </>;
                                            } else if (isTemplateOwner) {
                                                SBCRender = <div className="system-owner-wrapper">
                                                    <div style={{ textAlign: "center", marginBottom: "15px" }}>
                                                        <small>Shown for Template Owner only</small>
                                                    </div>
                                                    {SBCRender}
                                                    <hr />
                                                </div>;
                                            } else if (systemOwner) {
                                                SBCRender = <div className="system-owner-wrapper">
                                                    <div style={{ textAlign: "center", marginBottom: "15px" }}>
                                                        <i className="fa-solid fa-user-secret" title="System Owner Only" style={{ float: "left" }}></i>
                                                        <small>System Owner Only</small>
                                                    </div>
                                                    {SBCRender}
                                                    <hr />
                                                </div>;
                                            }
                                        }
                                    }

                                    var SRVRender = $this.getSRV(values);
                                    if (SRVRender !== null) {
                                        SRVRender = <>{SRVRender}<hr /></>;
                                    }

                                    var showAllowedIPs = true;
                                    var readOnlyIps = false;
                                    if (!$this.state.showHiddenFields
                                        && (template && template.hasOwnProperty('Visibility') && template.Visibility.AllowedIPs.indexOf('Hide') > -1)
                                    ) {
                                        showAllowedIPs = false;
                                    }
                                    if (template
                                        && template.hasOwnProperty('Visibility')
                                        && !template.Visibility.AllowedIPs.toLowerCase().includes('edit')
                                        && !template.Visibility.AllowedIPs.toLowerCase().includes('hide')) {
                                        readOnlyIps = true;
                                    }

                                    var edgeSBCFlags = systemOwner ? $this.getEdgeSBCFlags(values, errors, touched, setFieldValue) : null;

                                    return (
                                        <Form className="form form-new-pbx-service">
                                            <fieldset disabled={isSubmitting}>
                                                {FormHidden}

                                                <div className="user-form-parts-wrapper">
                                                    {NameCountryFormInputs}
                                                </div>

                                                <div className="user-form-parts-wrapper">
                                                    {TopFormInputs}

                                                    {showAllowedIPs
                                                        ? <AllowedIPs
                                                            formValues={values}
                                                            values={values}
                                                            errors={errors}
                                                            touched={touched}
                                                            setFieldValue={setFieldValue}
                                                            serviceName={"PBX"}
                                                            readOnly={readOnlyIps}
                                                        />
                                                        : null
                                                    }

                                                </div>

                                                <div className="user-form-parts-wrapper">
                                                    {PolicyFormInputs}
                                                </div>

                                                <div className="user-form-parts-wrapper">
                                                    {FormInputs}
                                                </div>

                                                {$this.getE164Render(values, errors, touched, setFieldValue, FormKeys, false)}

                                                <hr />
                                                {$this.renderIsEmergencyService()}
                                                {$this.renderDedicatedIpTag()}
                                                {$this.getDedicatedIps()}
                                                {SBCRender}

                                                {SRVRender}

                                                {SBCModeDialog}

                                                {edgeSBCFlags}

                                                {$this.generateSyncPart(values, handleChange)}

                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group form-group--with-central">
                                                            {self.props.children}

                                                            <div className="central-button-wrapper">
                                                                {revealButton}
                                                            </div>
                                                            <SubmitButton forceDisable={values.IsUIDisabled} isSubmitting={isSubmitting} className={"btn btn-primary btn--service-form"}>{self.props.saveText}</SubmitButton>
                                                            {closeButton}
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </Form>
                                    );
                                }}

                            </Formik>

                        </>
                    );
                }}
            />
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
        baseAccount: account.baseAccount
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PBXServiceForm));