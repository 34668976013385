import React, { Component } from 'react';
import ActionHeader from '../ActionHeader.js';
import ExpandingTable from '../ExpandingReactTable';
import { cmsGetWizardPrevPreReqsPromise } from '../CallMSAPI.js';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { canAccess } from '../CallMSUIHelpers.js';
import { connect } from 'react-redux';
import { formatUtcToLocal } from '../utils/dateFormatter.js'

class PreReqHistoryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.fetchData = this.fetchData.bind(this);
    }

    fetchData(filterValues, searchText, pageSize, page, sortSettings) {
        var self = this;

        var apiParams = {
            accountId: self.props.account.Id,
            objectType: 'eventLogs'
        };

        if (apiParams.accountId === undefined) {
            return;
        }

        if (pageSize) {
            apiParams['pageSize'] = pageSize;
        }

        if (page) {
            apiParams['currentPage'] = page;
        }

        if(sortSettings) {
            apiParams['SortBy'] = sortSettings.Column;
            apiParams['SortDirection'] = sortSettings.SortDirection;
        }

        return cmsGetWizardPrevPreReqsPromise(self.props.account.Id);
    }

    render() {
        var self = this;
        var cols = [
            {
                id: "Date",
                Header: () => <div style={{ textAlign: "left" }}>Date</div>,
                accessor: function (d) {
                    var formattedDate = formatUtcToLocal(d.CreatedOn);
                    return formattedDate.parsedSuccessfully
                        ? formattedDate.formattedLocal
                        : formattedDate.utcDateString;
                },
                filterable: false,
                sortable: false
            },
            {
                id: "User",
                Header: () => <div style={{ textAlign: "left" }}>User</div>,
                accessor: function (d) {
                    return d.UserUPN;
                },
                filterable: false,
                sortable: false
            }
        ];
        return (
                <>
                    <br />
                    <ActionHeader headerText="Event Log" smallHeader={true}/>
                    <br />
                    <ExpandingTable
                        fetchData={self.fetchData}
                        columns={cols}
                        loadingText={"Loading Data..."}
                        noDataText={"You don't have any Wizard PreRequisite history."}
                        showPagination={true}
                        pageSizeOptions={[10, 20, 50]}
                        className="-striped -highlight"
                        SubComponent={row => {
                            return (
                                <PreReqHistory
                                    date={row.original.CreatedOn}
                                    tenantId={row.original.TenantId}
                                    log={row.original.Log}
                                    userUPN={row.original.UserUPN}
                                    account={self.props.account.Id}
                                    roles={self.props.baseAccountRoles}
                                />
                            );
                        }}
                    />
                </>
            );
    }
}
export default connect(state => ({
    account: state.account.account,
    baseAccountRoles: state.account.baseAccount.roles
}))(PreReqHistoryPage);

export class PreReqHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jsonBlob: null,
            data: null,
            date: this.props.date,
            tenant: this.props.tenantId,
            account: this.props.account,
            userUPN: this.props.userUPN
        };
    }
    componentDidMount() {
        var self = this;
        var preReqResultsString = self.props.log.replaceAll("{", "{\n");
        preReqResultsString = preReqResultsString.replaceAll(",", ",\n");
        preReqResultsString = preReqResultsString.replaceAll("}", "\n}");
        self.setState({ jsonBlob: preReqResultsString }, function () {
            var listResults = self.props.log.split("Full Data")[0].replaceAll("{", "").replaceAll("}", "").replaceAll('\"', "").split(",");
            self.setState({ data: listResults });
        });
    }
    render() {
        var systemOwner = canAccess('WizardDebug', this.props.roles);
        var date = this.state.date;
        if (date) {
            var formattedDate = formatUtcToLocal(date);
            date = formattedDate.parsedSuccessfully
                ? formattedDate.formattedLocal
                : formattedDate.utcDateString;
        }
        return (
            <>
                <br />
                <table className="table">
                    <tbody>
                        <tr>
                            <th>
                                <label>
                                    Checked on:
                                </label>
                            </th>
                            <td>
                                {date}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>
                                    Tenant ID:
                                </label>
                            </th>
                            <td>
                                {this.state.tenant}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>
                                    User:
                                </label>
                            </th>
                            <td>
                                {this.state.userUPN}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>
                                    Results:
                                </label>
                            </th>
                            <td>
                                <div>
                                    <ul style={{listStyle:'none', margin: '0', padding: '0'}}>
                                        {this.state.data ? this.state.data.map(function (entry, i) {
                                            var check = entry.split(':');
                                            if (check && check.length == 2) {
                                                switch (check[1]) {
                                                    case 'passed':
                                                        return (
                                                            <li style={styles.li}>
                                                                <div style={styles.liDiv}>{check[0]}</div>
                                                                <div style={styles.liDiv}><Icon iconName="CheckMark" className="prerequisite-status-icon--success" style={{ fontWeight: 'bold', fontSize: '25px'}} /></div>
                                                            </li>
                                                            );
                                                        break;
                                                    case 'failed':
                                                        return (
                                                            <li style={styles.li}>
                                                                <div style={styles.liDiv}>{check[0]}</div>
                                                                <div style={styles.liDiv}><Icon iconName="CalculatorMultiply" className="prerequisite-status-icon--failure" style={{ fontWeight: 'bold', fontSize: '25px'}} /></div>
                                                            </li>
                                                        );
                                                        break;
                                                    case 'true':
                                                        return (
                                                            <li style={styles.li}>
                                                                <div style={styles.liDiv}>{check[0]}</div>
                                                                <div style={styles.liDiv}><Icon iconName="CheckMark" className="prerequisite-status-icon--success" style={{ fontWeight: 'bold', fontSize: '25px'}} /></div>
                                                            </li>
                                                        );
                                                        break;
                                                    case 'false':
                                                        return (
                                                            <li style={styles.li}>
                                                                <div style={styles.liDiv}>{check[0]}</div>
                                                                <div style={styles.liDiv}><Icon iconName="CalculatorMultiply" className="prerequisite-status-icon--failure" style={{ fontWeight: 'bold', fontSize: '25px'}} /></div>
                                                            </li>
                                                        );
                                                        break;
                                                    default:
                                                        return null;
                                                        break;
                                                }
                                            } else {
                                                return null;
                                            }
                                        }) : null}
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        {systemOwner ?
                            <tr className="system-owner-action">
                                <th>
                                    <label>
                                        Results raw data:  <i className="fa-solid fa-user-secret" title="System Owner Only"></i>
                                    </label>
                                </th>
                                <td>
                                    <pre className="large-debug-text">{this.state.jsonBlob}</pre>
                                </td>
                            </tr>
                            : null}
                    </tbody>
                </table>
            </>
            );
    }
}

var styles = {
    li: { width: '100%', textAlign: 'left', display: 'flex', alignItems: 'center' },
    liDiv: { flexBasis: '50%', boxSizing: 'border-box' }
};