import { formatUtcToLocal } from '../../utils/dateFormatter.js'

var _ = require('lodash');
require('es6-shim');


const data = {
    "IsEnabled": true,
    "TemplateName": "",
    "CountryId": "",
    "StateId": "",
    "Realm": "",
    "Proxy": "",
    "CertificationName": "",
    "SBCMode": "",
    "AllowedIPs": [""],
    "ServiceVariantCode": "",
    "ServiceSyncModuleId": "",
    //todo in the ui
    "CallingPolicy": "",
    //
    "VMCallingPolicy": "VM",
    "MOHCallingPolicy": 'MOHTEAMS',
    "OriginalCallingPolicy": "",
    "DefaultCallingPolicy": [""],
    "Expiry": "",
    "Protocol": "",
    "TransferMode": "",
    "SuppressContactDataParam": '',
    "EncryptMedia": '',
    "AllowedCodecs": [],
    "E164Mode": "",
    "FromHeaderType": "",
    "PAIHeaderType": "",
    "FromPBXInternationalPrefix": "",
    "FromPBXNationalPrefix": "",
    "ToPBXInternationalPrefix": "",
    "ApplyPAIDPrefix": "",
    "ToPBXNationalPrefix": "",
    "LogoURL": "",
    "AllowReadSBCAllocation": true,
    "EdgeSBCFlags": "",
    "Visibility": {
        "ShowLocation": "EditMandatory",
        "ShowRealm": "EditMandatory",
        "ShowProxy": "EditOptional",
        "ShowSBCMode": "EditMandatory",
        "ShowAllowedIPs": "EditMandatory",
        "ShowServiceSyncModule": "Hide",
        "ShowProtocol": "EditMandatory",
        "ShowTransferMode": "EditMandatory",
        "ShowSuppressContactDataParam": "EditMandatory",
        "ShowEncryptMedia": "EditMandatory",
        "ShowAllowedCodecs": "LockedEditOptional",
        "ShowFromHeaderType": "Hide",
        "ShowPAIHeaderType": "Hide",
        "ShowDialingPrefixes": "Hide",
        "ShowExpiry": "LockedEditOptional",
        "ShowCredentials": "EditMandatory",
        "ShowAuthUsername": "EditMandatory",
        "ShowApplyPAIDPrefix": "Hide",
        //todo in ui
        "ShowCallingPolicy": "EditMandatory",
        //
        "ShowExtension": "Hide"
    },
    "Labels": {
        "IdentifierLabel": "",
        "AuthUsernameLabel": "",
        "RealmLabel": "",
        "ProxyLabel": "",
    },
    "DedicatedIPTag": null,
    "EmergencyAccountServiceId": null
}

export const arrayToString = (values) => {
    return values === null || values.length === 0
        ? ""
        : values.join(" ");
}

export const arrayToCommaJoinedString = (values) => {
    return values === null || values.length === 0
        ? ""
        : values.join(",");
}

export const getExistingTemplateFormData = (template) => {
    var response = _.cloneDeep(data);

    if (template !== null && template !== undefined) {
        response.IsEnabled = template.IsEnabled;
        response.TemplateName = template.Name;
        response.CountryId = template.CountryId === null
            ? ""
            : template.CountryId;
        response.StateId = template.StateId === null
            ? ""
            : template.StateId;
        response.Realm = template.Realm != null ? template.Realm : "";
        response.Proxy = template.Proxy != null ? template.Proxy : "";
        response.CertificationName = template.Certification != null ? template.Certification : "";
        response.SBCMode = template.SBCMode;
        response.ServiceVariantCode = template.ServiceVariantCode;
        response.AllowedIPs = template.AllowedIPs !== null
            ? template.AllowedIPs
            : [""];

        response.ServiceSyncModuleId = template.ServiceSyncModuleId;
        if (template.CallingPolicy && template.CallingPolicy.includes('_')) {
            let callingPolicies = template.CallingPolicy.split('_');
            response.VMCallingPolicy = callingPolicies[0];
            response.MOHCallingPolicy = callingPolicies[1];
        }
        else {
            response.VMCallingPolicy = 'VM';
            response.MOHCallingPolicy = 'MOHTEAMS';
        }
        if (template.CallingPolicy == "VM_MOHTEAMS" || (response.VMCallingPolicy == 'VM' && response.MOHCallingPolicy == 'MOHTEAMS')) {
            response.DefaultCallingPolicy = [''];
        } else {
            response.DefaultCallingPolicy = ['', 'custom'];
        }
        response.Expiry = template.Expiry;
        response.Protocol = template.Protocol;
        response.TransferMode = template.TransferMode;
        response.SuppressContactDataParam = template.SuppressContactDataParam;
        response.EncryptMedia = template.EncryptMedia;
        response.AllowedCodecs = template.AllowedCodecs;
        response.E164Mode = template.E164Mode;
        response.FromHeaderType = template.FromHeaderType;
        response.PAIHeaderType = template.PAIHeaderType;
        response.ApplyPAIDPrefix = template.ApplyPAIDPrefix;
        response.FromPBXInternationalPrefix = template.FromPBXInternationalPrefix;
        response.ToPBXInternationalPrefix = template.ToPBXInternationalPrefix;
        response.FromPBXNationalPrefix = template.FromPBXNationalPrefix;
        response.ToPBXNationalPrefix = template.ToPBXNationalPrefix;
        response.LogoURL = template.LogoURL;
        response.AllowReadSBCAllocation = template.AllowReadSBCAllocation;
        response.EdgeSBCFlags = template.EdgeSBCFlags;

        if (template.Labels) {
            response.Labels.IdentifierLabel = template.Labels.Identifier != null
                ? template.Labels.Identifier : "";
            response.Labels.AuthUsernameLabel = template.Labels.AuthUsername != null
                ? template.Labels.AuthUsername : "";
            response.Labels.RealmLabel = template.Labels.Realm != null
                ? template.Labels.Realm : "";
            response.Labels.ProxyLabel = template.Labels.Proxy != null
                ? template.Labels.Proxy : "";
        }

        response.Visibility.ShowLocation = template.Visibility.CountryId;
        response.Visibility.ShowRealm = template.Visibility.Realm;
        response.Visibility.ShowProxy = template.Visibility.Proxy;
        response.Visibility.ShowSBCMode = template.Visibility.SBCMode;
        response.Visibility.ShowAllowedIPs = template.Visibility.AllowedIPs;
        response.Visibility.ShowServiceSyncModule = template.Visibility.ServiceSyncModuleId;
        response.Visibility.ShowCallingPolicy = template.Visibility.CallingPolicy;
        response.Visibility.ShowExpiry = template.Visibility.Expiry;
        response.Visibility.ShowProtocol = template.Visibility.Protocol;
        response.Visibility.ShowTransferMode = template.Visibility.TransferMode;
        response.Visibility.ShowSuppressContactDataParam = template.Visibility.SuppressContactDataParam;
        response.Visibility.ShowEncryptMedia = template.Visibility.EncryptMedia;
        response.Visibility.ShowAllowedCodecs = template.Visibility.AllowedCodecs;
        response.Visibility.ShowFromHeaderType = template.Visibility.FromHeaderType;
        response.Visibility.ShowPAIHeaderType = template.Visibility.PAIHeaderType;
        response.Visibility.ShowDialingPrefixes = template.Visibility.DialingPrefixes;
        response.Visibility.ShowCredentials = template.Visibility.Credentials;
        response.Visibility.ShowApplyPAIDPrefix = template.Visibility.ApplyPAIDPrefix;
        response.Visibility.ShowAuthUsername = template.Visibility.AuthUsername;
        response.Visibility.ShowExtension = template.Visibility.ShowExtension;
        response.DedicatedIPTag = template.DedicatedIPTag ? template.DedicatedIPTag : '';
        response.EmergencyAccountServiceId = template.EmergencyAccountServiceId;
    }

    return response;
}

export const getNewTemplateFormData = (serviceVariantCode) => {
    var response = _.cloneDeep(data);
    response.ServiceVariantCode = serviceVariantCode;
    return response;
}

export const getPostTemplateData = (template) => {
    template.AllowedCodecs = template.AllowedCodecs === null || template.AllowedCodecs.length === 0
        ? ""
        : arrayToString(_.map(template.AllowedCodecs, x => x.value));

    template.AllowedIPs = arrayToCommaJoinedString(template.AllowedIPs);

    _.forOwn(template.Visibility, function (value, key) {
        template.Visibility[key] = value.hasOwnProperty("value")
            ? value.value
            : value
    });

    template.CountryId = template.StateId === null
        ? template.CountryId
        : null

    return template;
}

export const getTemplateHistorySelectOptions = (currentData, historicalData) => {
    var options = [];

    if (currentData) {
        options.push({
            label: createHistoralRecordDateLabel(true, currentData.ModifiedOn),
            value: currentData.Id
        })
    }
    if (historicalData) {
        historicalData.forEach((result) => {
            options.push({
                label: createHistoralRecordDateLabel(false, result.ModifiedOn),
                value: result.HistoryId
            })
        });
    }

    return options;
}

const createHistoralRecordDateLabel = (isCurrentTemplate, date) => {
    let formattedDate = formatUtcToLocal(date);

    if (!formattedDate.parsedSuccessfully) {
        return date;
    }

    let dateLabel = `${formattedDate.fromNow} (${formattedDate.formattedLocal})`;

    if (isCurrentTemplate) {
        dateLabel += " Current";
    }

    return dateLabel;
}