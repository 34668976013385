import React, { PureComponent } from 'react';
import { getErrMsg, cmsAddServiceUser, cmsEditServiceUserPartPromise } from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import { ActionButton } from 'office-ui-fabric-react';
import ServiceUserForm from './ServiceUserForm';
import { connect } from 'react-redux';
import ServiceUserService from '../js-services/ServiceUserService.js';

var _ = require('lodash');

class NewUserForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            // Visual state
            hideForm: props.alwaysShowForm ? false : true,
        };

        // Need 'setState' from event handler
        this.toggleForm = this.toggleForm.bind(this);
        this.saveNewUser = this.saveNewUser.bind(this);
    }

    toggleForm(e) {
        var self = this;

        // We want to act on what will become the old value...
        this.props.setRefreshPause(this.state.hideForm);

        this.setState(prevState => ({
            hideForm: !prevState.hideForm
        }), function () {
            self.props.setFormOpen && self.props.setFormOpen(!self.state.hideForm);
        });


        if (e !== undefined) {
            e.preventDefault();
        }
    }

    saveNewUser(formik, values, setSubmitting, setErrors, resetForm) {
        var self = this;

        /**
         * Sync user update logic goes:
         * - For the sync parts, (currently only MS Teams supported), call a
         *   PUT to update PhoneNumber if the user supplied a different version
         *   to that which came from the sync.
         * - Add the new serviceuser, supplying either PartNId or PartN: object, for each side.
         * 
         */

        /* Any parts that have an existing ID and appear to have more fields
        * available we store the SerivceUserPart updates to happen. 
        * Part 1 has been included in the below function, as this part includes the service numbers, 
        * which should always be editable */
        var SUPUpdates = [];
        ['Part1','Part2'].forEach(function (part) {
            // ID could be user direct _or_ sync user part
            let id = null;
            if (values[part].SyncUserPart) {
                id = values[part].SyncUserPart.Id;
            }
            if (!id) {
                id = values[part].Id;
            }

            if (id && _.keys(values[part]).length > 1) {
                SUPUpdates.push({
                    supId: id,
                    values: _.clone(values[part])
                })
            }
        });

        // For those existing service user parts (which we will update later), collapse down to just the ID
        values = ServiceUserService.hashServiceUserParts(values);
        
        var partUpdatePromises = [];

        _.forEach(SUPUpdates, function (s) {
            partUpdatePromises.push(
                cmsEditServiceUserPartPromise(
                    self.props.account.Id,
                    s.values.AccountServiceId,
                    s.supId,
                    s.values
                )
            );
        });

        Promise.all(partUpdatePromises).then(() => {
            cmsAddServiceUser(
                self.props.account.Id,
                values,
                function (data) {
                    setSubmitting(false);

                    // Clear form, close form
                    if (!self.props.alwaysShowForm) {
                        self.toggleForm();
                    }
                    resetForm && resetForm(formik.getFormDefaults());

                    /* Fire further up chain updates (e.g. table redraw)
                        * if alwaysShowForm is true, then you are in the wizard,
                        * and the close form toggle is not used.
                        * The userUpdateTrigger function is usually used to reload
                        * the user table. In the wizard, no table is shown, so the normal function
                        * isn't used, instead a different callback (in the wizard.js file) is used
                        * which redirects the user to the users tab */

                    self.props.userUpdateTrigger &&
                        self.props.userUpdateTrigger(data);

                    toast.success("Service user successfully added");
                }
                , function (error) {
                    setSubmitting(false);
                    toast.error("Unable to add new user: " + getErrMsg(error));
                }
            );
            }).catch(error => {
                setSubmitting(false);
                toast.error("Unable to add new user settings: " + getErrMsg(error));
            });
        }

    render() {
        const services = this.props.services;
        return (
            <div className="domainForm">
                {this.props.alwaysShowForm ? null :
                    <div className="user-form-action-buttons user-form-action-buttons--top">
                        <ActionButton
                            iconProps={{ iconName: 'AddFriend' }}
                            allowDisabledFocus={true}
                            disabled={this.props.otherFormOpen}
                            onClick={this.toggleForm}
                        >
                            {!this.state.hideForm ? <strong>Add User</strong> : <span>Add User</span>}
                        </ActionButton>
                        {this.props.children}
                    </div>
                }
                {!this.state.hideForm
                    ? (
                        <div className="alert alert-info domain-form-wrapper">
                            {!services.loaded
                                ? (<p>Loading services...</p>)
                                : (<ServiceUserForm
                                    user={this.props.user}
                                    showParts={this.props.showParts}
                                    showDisable={this.props.showDisable}
                                    showDelete={this.props.showDelete}
                                    disableToggleCallback={this.props.disableToggleCallback}
                                    countries={this.props.countries}
                                    closeForm={this.toggleForm}
                                    submitCallback={this.saveNewUser}
                                    hideCloseButton={this.props.hideCloseButton}
                                    submitButtonText={this.props.submitButtonText}
                                />
                                )}
                        </div>
                    ) : null}
            </div>
        );
    }
}
const mapStateToProps = state => {
    const services = state.services;
    const account = state.account;
    return {
        account: account.account,
        services
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(NewUserForm);
