import React from 'react';
import { arrayOf, func, instanceOf } from "prop-types";
import { CallLog } from '../../models/CallLog';
import { CallLogType } from '../../models/Enums';
import DATE_FORMATS from '../../constants/dateFormats.js';
import { formatUtcDate } from '../../utils/dateFormatter.js'

const CallFlowDiagram = (props) => {

    const renderHeader = (title = '', className = '', iconClassName = '') => {
        return (
            <div title={`Column of call packets for ${title}`} className='col-sm-6 no-gutter'>
                <div className={className}>
                    <i className={`${iconClassName} fa-solid fa-server fa-3x`} />
                    <br />
                    {title}
                </div>

            </div>
        );
    }

    const renderLine = (x = new CallLog(), index = 0, borderColour = '') => {

        let direction = '';
        let floatDirection = '';
        let ariaLabel = '';

        switch (x.type) {
            case CallLogType.EdgeToApp:
                ariaLabel = 'From EdgeSBC to related application';
            case CallLogType.PBXToEdge:
                direction = 'diagram-line-right';
                floatDirection = 'float-left ms-5';
                ariaLabel = 'PBX To EdgeSBC';
                break;
            case CallLogType.EdgeToPBX:
                direction = 'diagram-line-left';
                floatDirection = 'float-right me-5';
                ariaLabel = 'Edge back to PBX';
                break;
        }

        return (
            <div aria-label={ariaLabel}>
                <div className='row mt-5 mb-1'>
                    <div className='col-sm-12'>
                        <span
                            role='button'
                            onClick={props.clickLineTitle}
                            className={`${floatDirection} text-primary fw-bolder m-2`}
                        >
                          sip: {index}: {x.content}
                        </span>
                    </div>
                </div>
                <div className={`${borderColour} m-0 mt-2 mb-2 border-2 border-top ${direction}`}>
                    <i className={`${borderColour} arrow arrow-right diagram-line-icon`} />
                </div>
                <span className={`text-muted m-2 ${floatDirection}`}>{formatUtcDate(x.time, `YY-MM-D ${DATE_FORMATS.TIME} [(UTC)]`)}</span>
            </div>
        );
    }

    const renderLines = () => {
        return props.callLogs.map((x = new CallLog(), i) => {
            i++;
            return (
                <div key={x.time} className='row equal border-start border-2 border-danger border-end'>
                    <div className='col-sm-12 no-gutter border-2 border-success'>
                        {x.type === CallLogType.PBXToEdge &&
                            renderLine(x, i, 'border-warning')
                        }
                        {x.type === CallLogType.EdgeToPBX &&
                            renderLine(x, i, 'border-warning')
                        }
                    </div>
                    {/* We don't record teams calls... comment out if changed...
                     * add border-end css class to col-sm-12 above 
                     * changed col-sm-12 above to col-sm-6
                       <div className='col-sm-6 no-gutter border-2 border-danger'>
                        {x.type === CallLogType.EdgeToApp &&
                            renderLine(x, i, 'border-danger')
                        }
                    </div>
                     */}

                </div>
            );
        });
    }

    return (
        <div className='row m-3'>
            <div className='col-sm-12'>
                <div className='row d-none d-lg-block'>
                    {renderHeader('207.97.189.173:5063', 'float-left text-success')}
                    {renderHeader('207.97.189.173:5063', 'float-right', 'float-right')}
                    {/* 
                     * We don't record teams calls... comment out if changed...
                     * change float-right above to text-center in iconClassName param...
                     * In renderHeader change col-sm-6 to col-sm-4...
                     renderHeader('207.97.189.173:5063', 'float-right', 'float-right')
                     */}
                </div>
                <div className='mt-2'>
                    {renderLines()}
                </div>
            </div>
        </div>
    );
}
CallFlowDiagram.defaultProps = {
    clickLineTitle: (_callLog = new CallLog()) => { },
    callLogs: [new CallLog()]
};
CallFlowDiagram.propTypes = {
    clickLineTitle: func,
    callLogs: arrayOf(instanceOf(CallLog)).isRequired,
};
export default CallFlowDiagram;