import React, { Component } from 'react';
import ServiceSyncOnOff from './ServiceSyncOnOff';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { TeachingBubble, MessageBar, MessageBarType, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { isSystemOwner, isValidWord, showConsoleLogs, isJson } from '../CallMSUIHelpers.js';
import { cmsGetCountriesPromise, cmsGetStatePromise } from '../CallMSAPI';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import {
    getCallPolicyFormObject,
    getCallPolicyVMFormObject,
    getCallPolicyMOHFormObject
} from '../CallingPolicyHelpers';
import {
    CountryHOC,
    E164Form,
    allCodecs,
    checkHasCredentials,
    checkWillRegister,
    expandCodecs,
    formKeysToComponentsFunc,
    getDistinctArrayValues,
    getFromHeaderTypeTrunkOptions,
    getPAIHeaderTypeTrunkOptions,
    getSBCModeOptions,
    getTransferModeOptions,
} from './ServiceFormHelpers.js'
import { ValidateRealm, ValidateProxy } from './ServiceValidationHelpers.js';

import { connect } from 'react-redux';
import * as actions from '../store/actions/index';
import SbcReallocateDropDown from '../components/service/SbcReallocateDropDown';
import SBCNameHelp from '../components/service/SbcServiceHelp';
import FieldWithDisposition from "./FieldWithDisposition";

var _ = require('lodash');
var checkIp = require('check-ip');

class PBXTrunkServiceForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showE164: false,
            showE164Extended: false,
            showE164Help: false,
            showDedicatedIPHelp: false,
            showSBCIPHelp: false,
            showHiddenFields: false,
            showHiddenFieldsHelp: false,
            showGlobalPBXUpgradeDialog: false
        };
        this.formInputDefault = this.formInputDefault.bind(this);
        this.toggleSBCIPHelp = this.toggleSBCIPHelp.bind(this);
        this.dismissSBCIPHelp = this.dismissSBCIPHelp.bind(this);
        this.toggleSRVHelp = this.toggleSRVHelp.bind(this);
        this.dismissSRVHelp = this.dismissSRVHelp.bind(this);
        this.toggleHiddenHelp = this.toggleHiddenHelp.bind(this);
        this.dismissHiddenHelp = this.dismissHiddenHelp.bind(this);
        this.validatePBXValues = this.validatePBXValues.bind(this);
        this.validateTrunkValues = this.validateTrunkValues.bind(this);
        this.validateTrunkNumbers = this.validateTrunkNumbers.bind(this);
        this.getFormKeys = this.getFormKeys.bind(this);
        this.getNameCountryFormKeys = this.getNameCountryFormKeys.bind(this);
        this.toggleOwnerHiddenFields = this.toggleOwnerHiddenFields.bind(this);
        this.getHiddenFieldToggleButton = this.getHiddenFieldToggleButton.bind(this);
        this.displayMisconfigurationWarnings = this.displayMisconfigurationWarnings.bind(this);
        this.getGlobalPBXUpgradeDialog = this.getGlobalPBXUpgradeDialog.bind(this);
        this.validateBulkImportRanges = this.validateBulkImportRanges.bind(this);
        this.resetPBXSIPMapping = this.resetPBXSIPMapping.bind(this);
    }

    toggleDedicatedIPHelp() {
        this.setState(prevState => ({ showDedicatedIPHelp: !prevState.showDedicatedIPHelp }));
    }

    dismissDedicatedIPHelp() {
        this.setState({ showDedicatedIPHelp: false });
    }

    toggleSBCIPHelp() {
        this.setState(prevState => ({ showSBCIPHelp: !prevState.showSBCIPHelp }));
    }

    dismissSBCIPHelp() {
        this.setState({ showSBCIPHelp: false });
    }

    toggleSRVHelp() {
        this.setState(prevState => ({ showSRVHelp: !prevState.showSRVHelp }));
    }

    dismissSRVHelp() {
        this.setState({ showSRVHelp: false });
    }

    toggleE164Help() {
        this.setState(prevState => ({ showE164Help: !prevState.showE164Help }));
    }

    dismissE164Help() {
        this.setState({ showE164Help: false });
    }

    toggleHiddenHelp() {
        this.setState(prevState => ({ showHiddenFieldsHelp: !prevState.showHiddenFieldsHelp }));
    }

    dismissHiddenHelp() {
        this.setState({ showHiddenFieldsHelp: false });
    }

    toggleE164() {
        this.setState(prevState => ({
            showE164: !prevState.showE164
        }))
    }

    toggleE164Extended() {
        this.setState(prevState => ({
            showE164Extended: !prevState.showE164Extended
        }))
    }

    getE164Render(values, errors, touched, setFieldValue, AllInputs, isTrunk) {

        return (
            <CountryHOC
                CountryId={values.CountryId}
                StateId={values.StateId}
                accountId={this.props.account.Id}
            >
                <E164Form
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    AllInputs={AllInputs}
                    isTrunk={isTrunk}
                    account={this.props.account}
                />
            </CountryHOC>
        );
    }

    getServiceId() {
        // If existing service use that, otherwise available service ID...
        return this.props.currentService
            ? this.props.currentService.Id
            : (this.props.availableService ? this.props.availableService.Id : null);
    }

    getVariantId() {
        return this.props.currentService
            ? this.props.currentService.Variant.Id
            : (this.props.variant ? this.props.variant.Id : null);
    }

    getVariantObj() {
        return this.props.currentService
            ? this.props.currentService.Variant
            : (this.props.variant ? this.props.variant : null);
    }

    getPbxName() {
        // Existing service?
        if (this.props.currentService && this.props.currentService.Name) {
            return this.props.currentService.Name
        }

        // Otherwise, use pbx template name
        var baseName = '';
        if (this.props.pbxTemplate) {
            baseName = this.props.pbxTemplate.Name + ' ';
        }
        baseName += this.props.serviceName;

        if (this.props.existingCount) {
            baseName += ' ' + (this.props.existingCount + 1);
        }

        return baseName;
    }

    addCountryStateValues(baseDefaults) {
        /**
         * State and Country are special cases.
         *
         * We want to use service values, then template, then account defaults.
         * The difference is that Country might be set on a service but State
         * on an account default, so the use of account defaults needs to
         * happen only if both state/country values are blank from
         * service/template.
         */
        var state = this.formServiceInputDefault('StateId', null);
        var country = this.formServiceInputDefault('CountryId', null);

        // Fall back to account defaults
        if (state === null && country === null) {
            state = this.getDefault('StateId');
            country = this.getDefault('CountryId');
        }

        if (state !== null && state !== '') {
            baseDefaults['StateId'] = state;
        } else if (country !== null && country !== '') {
            baseDefaults['CountryId'] = country;
        }

        return baseDefaults;
    }

    getDefault(val) {
        return (this.props.account && this.props.account[val]
            ? this.props.account[val] : '');
    }

    formServiceInputDefault(val, finalDefault = '') {
        var temp = null;
        if (   this.props.hasOwnProperty('currentService')
            && this.props.currentService
            && this.props.currentService[val]
        ) {
            temp = this.props.currentService[val];

        } else if (this.props.hasOwnProperty('pbxTemplate')
            && this.props.pbxTemplate
            && this.props.pbxTemplate[val]
        ) {
            temp = this.props.pbxTemplate[val];

        }
        if (temp === null) {
            return finalDefault;
        }
        return temp;
    }

    formInputDefault(group, val, finalDefault = '') {
        var temp;
        if (   this.props.hasOwnProperty('currentService')
            && this.props.currentService
            && this.props.currentService.hasOwnProperty(group)
        ) {
            temp = this.props.currentService[group][val];
        } else if (this.props.hasOwnProperty('pbxTemplate') && this.props.pbxTemplate) {
            temp = this.props.pbxTemplate[val];
        }
        if (    temp === null || temp === undefined || temp === ''
            || (Array.isArray(finalDefault) && Array.isArray(temp) && temp.length === 0)
        ) {
            return finalDefault;
        }
        return temp;
    }

    formKeysToComponents(values, errors, touched, setFieldValue, FormKeys) {
        var self = this;
        var res = formKeysToComponentsFunc(values, errors, touched, setFieldValue, FormKeys, self);
        return res;
    }

    // Given the supplied set of form keys, is at least one of the form keys visible?
    formKeysHasSomeVisible(FormKeys) {
        var out = false;
        FormKeys.forEach(function (obj) {
            if (obj.State === 'Hide' || obj.Type === 'hidden') {
                // Hidden, so no-op
            } else if (!obj.HiddenOnly) {
                out = true;
            }
        });

        return out;
    }

    getEmergencyTrunkKey() {
        return [
          {
            Id: "Emergency",
            Label: "Emergency Trunk",
            Type: "select",
            HelpTitle: "Emergency Trunk",
            Group: "EmergencyTrunk",
            Help: (
              <>
                <p>Set this trunk as an emergency trunk.</p>
              </>
            ),
            Values: [
              { label: "Disabled", value: false },
              { label: "Enabled", value: true },
            ]
          },
        ];
    }

    hasEmergencyTrunkFeature(account) {
        return (
            account &&
            account.Features &&
            account.Features.includes("EMERGENCYTRUNK")
        );
    }

    getBaseFormKeys() {
        var out = [
            {
                Id: "ServiceSyncModuleId",
                Label: 'Service Sync Module',
                Type: 'text',
                ViewOrder: 1,
                DisableOverride: true
            },
        ];
        return out;
    }

    getPBXFormKeys(isTrunk, values) {
        var self = this;

        var serviceName = 'PBX';
        if (isTrunk) {
            serviceName = 'Trunk';
        }
        var showTrueTranfer = false;
        if (self.props.canSelectTrueTransfer || self.props.trueTransferSupported || values['TransferMode'] === 'All') {
            showTrueTranfer = true;
        }

        var transferModeOps = getTransferModeOptions(
            isTrunk,
            self.props.canSelectTrueTransfer || self.props.trueTransferSupported,
            values.TransferMode,
            self.props.baseAccountInfo.Roles,
            self.props.account.BrandShortName
        );

        var globalPBXUpgradeAvailable = 'hidden';
        if (self.props.currentService
            && self.props.currentService.PBXSettings.SBCMode === 'StandardRegistration'
            && self.props.pbxTemplate
            && self.props.pbxTemplate.SBCMode === 'GlobalRegistration'
        ) {
            globalPBXUpgradeAvailable = 'checkbox';
        }

        let SBCMode = getSBCModeOptions(isTrunk, values);
        let SBCModeOptions = SBCMode.options;

        var showExtendedHeaderTypes = false;
        if (   (values['FromHeaderType'] && values['FromHeaderType'].indexOf('_') != -1)
            || (values['PAIHeaderType'] && values['PAIHeaderType'].indexOf('_') != -1)
        ) {
            showExtendedHeaderTypes = true;
        }

        var out = [
            {
                Id: "Realm",
                Label: 'SIP Domain',
                Type: 'text',
                Group: "AuthSection",
                ViewOrder: 1,
                HelpTitle: 'SIP Domain',
                Help: (
                    <>
                        <p>This is usually just the IP address or public host name used to reach the PBX.
                            If your PBX requires you to use a specific value here,
                            then do so and specify the IP address or public host name in the SIP Proxy field. </p>
                        <p>Do not enter a port number on the SIP Domain.</p>
                    </>
                )
            },
            {
                Id: "Proxy",
                Label: 'SIP Proxy',
                Type: 'text',
                Group: "AuthSection",
                ViewOrder: 1,
                HelpTitle: 'SIP Proxy',
                Help: (
                    <>
                        <p>This is usually left blank but can be used to specify the IP address or public host name to
                            reach the PBX if the PBX requires the SIP domain to be set to a different value.</p>
                        <p>If you need to specify a different port for registration to your PBX(default is 5060),
                            a port number can be specified by additionally setting a proxy address and appending a
                            colon and the number to the SIP Proxy field. e.g. myhostname.com:5056</p>
                    </>
                ),
            },
            // DoRegistration is no longer used.
            // FauxDoRegistration will basically map to/from the NumSBC columns
            // Note it's a string, not boolean, work around formik not keeping to boolean type in a predictable way.
            {
                Id: "FauxDoRegistration",
                Label: 'Authentication Type',
                Type: 'select',
                Group: "AuthSection",
                VisibilityKey: 'DoRegistration',
                Values: SBCModeOptions,
                ViewOrder: 4,
                HelpTitle: 'Authentication Type',
                Help: (
                    <>
                        <p>This should be set to 'Registration' unless you are deploying an advanced configuration and have been advised otherwise.</p>
                    </>
                ),
            },
            {
                Id: "UseGlobalRegistration",
                Label: 'Use Global PBX',
                Type: globalPBXUpgradeAvailable,
                CheckedValue: 'GlobalRegistration',
                CheckedLabel: "Upgrade to use Global PBX",
                NewValueCB: function (setFieldValue, newValue) {
                    if (newValue.includes('GlobalRegistration')) {
                        self.setState({ showGlobalPBXUpgradeDialog: !self.state.showGlobalPBXUpgradeDialog });
                    }
                },
                ViewOrder: 40
            },
            {
                Id: "Expiry",
                Label: 'Expiry (seconds)',
                Type: 'text',
                ViewOrder: 20,
                HelpTitle: 'Expiry',
                Help: (
                    <>
                        <p>The setting is used to determine how long the registration pinhole should remain open between registration attempts.</p>
                    </>
                ),
            },
            {
                Id: "Protocol",
                Label: 'Protocol',
                Type: 'select',
                Values: [
                    { label: 'Select a Value', value: '' },
                    { label: 'UDP', value: 'udp' },
                    { label: 'TCP', value: 'tcp' },
                    { label: 'TLS', value: 'tls' }
                ],
                HelpTitle: 'Protocol',
                Help: (
                    <>
                    <p>Note: For TLS the realm or proxy you enter must have a valid certificate and support TLS v1.2.</p>
                    <p>A valid certificate is one that is in-date, matches the hostname (or offers a suitable wildcard) and is publicy trusted (via PKI).</p>
                    </>
                ),
                ViewOrder: 20
            },
            {
                Id: "SBCMode",
                Label: 'SBC Mode',
                Type: 'hidden',
                ViewOrder: 20
            },
            {
                Id: "TransferMode",
                Label: 'Transfer Mode',
                HelpTitle: 'Transfer Mode',
                //Label: 'Propagate Refer',
                //HelpTitle: 'Propagate Refer',
                Help: (
                    <>
                    <p>Select '{serviceName} handles transfers' to propagate received SIP REFER messages 
                       from Microsoft upstream to this service. If set to 'Teams handles transfers' then
                       transfers are bridged out as new calls.</p>
                    <p>'{serviceName}  handles transfers' should be selected if you have users in a Call 
                        Center, but otherwise select 'Teams handles transfers' as this will allow consultative
                        transfers to merge call legs.
                    </p>
                    {showTrueTranfer ?
                        <p>'True Transfer' is optimal where all Teams users are connected to the PBX, allowing
                            transfers directly to other Teams users to simultaneously transfer the call on the PBX.
                            Using True Transfer if some Teams users are not connected to the PBX will produce sub-optimal
                            experience and other transfer methods may be preferred.</p>
                        : null}
                    </>
                ),
                Type: 'select',
                Values: transferModeOps,
                ViewOrder: 20
            },
            {
                Id: "SuppressContactDataParam",
                Label: 'Suppress Contact Data Param',
                Type: 'select',
                Values: [
                    { label: 'Select a Value', value: '' },
                    { label: 'Yes', value: true },
                    { label: 'No', value: false }
                ],
                ViewOrder: 20
            },

            // Special case, we have a custom AllowedIPs component we show
            {
                Id: "AllowedIPs",
                Label: 'Allow IPs',
                Type: 'hidden',
                ViewOrder: 20
            },
            {
                Id: "EncryptMedia",
                Label: 'Encrypt Media',
                Type: 'select',
                Values: [
                    { label: 'Select a Value', value: '' },
                    { label: 'Yes', value: true },
                    { label: 'No', value: false }
                ],
                ViewOrder: 25
            },
            {
                Id: "AllowedCodecs",
                Label: 'Override Codecs',
                Type: 'sortablelist',
                Placeholder: 'Pass Through All Codecs',
                Values: allCodecs(),
                ViewOrder: 25,
                HelpTitle: 'Override Codecs',
                Help: (
                    <>
                    <p>Leave blank to pass through all available codecs for each side of the call.</p>
                    <p>If specific codecs are selected here only those will be allowed in the SDP payload, other codecs will be removed.</p>
                    <p>You can drag and drop the codecs shown to specify the codec preference.</p>
                    </>
                )
            },
        ];

        out = out.concat([
            getCallPolicyFormObject(
                function (setFieldValue, newValue) {
                    if (!newValue || newValue.length === 0 || (newValue.length === 1 && newValue[0] === '')) {
                        // Reset to Teams 'defaults' if no override/manage values supplied
                        setFieldValue('VMCallingPolicy', 'VM');
                        setFieldValue('MOHCallingPolicy', 'MOHTEAMS');
                    } else if (values && values['OriginalCallingPolicy']) {
                        // Selected to manage values, restore to any supplied values in content
                        var splitPolicy = values['OriginalCallingPolicy'].split('_');
                        setFieldValue('VMCallingPolicy', splitPolicy[0]);
                        setFieldValue('MOHCallingPolicy', splitPolicy[1]);
                    }
                }
            ),
            getCallPolicyVMFormObject(values),
            getCallPolicyMOHFormObject(values, (isTrunk ? 'Trunk' : 'PBX'))
        ]);

        if (isTrunk) {
            out = out.concat([
                {
                    Id: "FromHeaderType",
                    Label: 'From Header',
                    Type: 'select',
                    HelpTitle: 'From SIP Header Format',
                    Help: (
                        <ul>
                            <li><strong>Trunk Caller Id</strong>: The phone number assigned to the user in the portal</li>
                            <li><strong>SIP Identifier</strong>: The SIP username</li>
                            <li><strong>Passthrough Caller Id</strong>: The caller ID given by the far end (e.g. Teams).
                            Caller Id is extracted from the From Header provided by Microsoft Teams. Its value depends on the specific call flow, i.e. for a forwarded call it will be the original caller.
                            </li>
                        </ul>
                    ),
                    Values: getFromHeaderTypeTrunkOptions('Select a value', showExtendedHeaderTypes),
                },
                {
                    Id: "PAIHeaderType",
                    Label: 'P-Asserted-Identity Header',
                    Type: 'select',
                    HelpTitle: 'P-Asserted-Identity SIP Header Format',
                    Help: (
                        <ul>
                            <li><strong>Not Present</strong>: Do not set any P-Asserted-Identity header</li>
                            <li><strong>Trunk User Number</strong>: The phone number assigned to the user in the portal</li>
                            <li><strong>SIP Identifier</strong>: The SIP username</li>
                            <li><strong>Passthrough Caller Id</strong>: The caller ID given by the far end (e.g. Teams).
                            Caller Id is extracted from the From Header provided by Microsoft Teams. Its value depends on the specific call flow, i.e. for a forwarded call it will be the original caller.
                            </li>
                        </ul>
                    ),
                    Values: getPAIHeaderTypeTrunkOptions('Select a value', showExtendedHeaderTypes),
                },
                {
                    Id: "ApplyPAIDPrefix",
                    Label: 'Apply PAID Prefix',
                    Type: 'checkbox',
                    CheckedLabel: 'Prefix applied',
                    CheckedValue: 'checked',
                    HelpTitle: 'Apply PAID Prefix',
                    Help: (
                        <>
                            <p>Check this box to toggle number formatting on the request headers.</p>
                            <p> Enabling this will remove the leading plus sign from numbers in the FROM and PAI headers of the request.</p>
                        </>
                    )
                }
            ]);

        } else {
            out = out.concat([
                {
                    Id: "FromHeaderType",
                    Label: 'From Header Format',
                    Type: 'select',
                    HelpTitle: 'From SIP Header Format',
                    Help: (
                        <ul>
                            <li><strong>SIP Identifier</strong>: The SIP username</li>
                            <li><strong>Passthrough Caller Id</strong>: The caller ID given by the far end (e.g. Teams).
                            Caller Id is extracted from the From Header provided by Microsoft Teams. Its value depends on the specific call flow, i.e. for a forwarded call it will be the original caller.
                            </li>
                        </ul>
                    ),
                    Values: [
                        { label: 'Select a Value', value: '' },
                        { label: 'SIP Identifier', value: "SIPIdentifier" },
                        { label: 'Passthrough Caller Id', value: "PassthroughCallerId" },
                    ]
                },
                {
                    Id: "PAIHeaderType",
                    Label: 'P-Asserted-Identity Header Format',
                    Type: 'select',
                    HelpTitle: 'P-Asserted-Identity SIP Header Format',
                    Help: (
                        <ul>
                            <li><strong>Not Present</strong>: Do not set any P-Asserted-Identity header</li>
                            <li><strong>SIP Identifier</strong>: The SIP username</li>
                            <li><strong>Passthrough Caller Id</strong>: The caller ID given by the far end (e.g. Teams).
                            Caller Id is extracted from the From Header provided by Microsoft Teams. Its value depends on the specific call flow, i.e. for a forwarded call it will be the original caller.
                            </li>
                        </ul>
                    ),
                    Values: [
                        { label: 'Select a Value', value: '' },
                        { label: 'Not Present', value: "NotPresent" },
                        { label: 'SIP Identifier', value: "SIPIdentifier" },
                        { label: 'Passthrough Caller Id', value: "PassthroughCallerId" },
                    ]
                },
                {
                    Id: "ApplyPAIDPrefix",
                    Label: 'Apply PAID Prefix',
                    Type: 'checkbox',
                    CheckedLabel: 'Prefix applied',
                    CheckedValue: 'checked',
                    HelpTitle: 'Apply PAID Prefix',
                    Help: (
                        <>
                            <p>Check this box to toggle number formatting on the request headers.</p>
                            <p> Enabling this will remove the leading plus sign from numbers in the FROM and PAI headers of the request.</p>
                        </>
                    )
                }
            ]);
        }

        //THIS IS PBXSETTINGS
        out = out.concat([
            {
                Id: 'OutsideLinePrefix',
                Label: 'Outside Line Prefix',
                ViewOrder: 50,
                VisibilityKey: 'DialingPrefixes',
                Type: 'number',
                HelpTitle: 'Outside Line Dial Prefix',
                Help: (
                    <p>If your PBX/Trunk requires dialing a digit for external calls/outside line, enter it here. It will then be automatically added to the start of the number dialed for calls out of Teams where the number dialed is recognized as external (typically anything 7 digits or longer). You will not need to manually enter the number each time you place a call.</p>
                ),
            },
            {
                Id: 'ConnectionConcurrency',
                ExtraClass: 'hide',
                Type: 'hidden',
                Label: 'Connection Concurrency'
            },
            {
                Id: 'E164Mode',
                Label: 'E164 Number Format',
                ViewOrder: 50,
                VisibilityKey: 'DialingPrefixes',
                Type: 'select',
                // Fire off some extra actions when the E164 mode is changed to Localized
                onChangeHook: function (name, newVal, values, setFieldValue) {
                    if (newVal === 'Localized'
                        && !values['FromPBXInternationalPrefix']
                        && !values['FromPBXNationalPrefix']
                        && !values['ToPBXInternationalPrefix']
                        && !values['ToPBXNationalPrefix']
                    ) {
                        var handleCountryCb = function (fullCountry) {
                            // map null to emptry strings to pass API validation
                            if (!fullCountry.DefaultInternationalDialPrefix) {
                                fullCountry.DefaultInternationalDialPrefix = '';
                            }
                            if (!fullCountry.DefaultNationalDialPrefix) {
                                fullCountry.DefaultNationalDialPrefix = '';
                            }
                            setFieldValue('FromPBXInternationalPrefix', fullCountry.DefaultInternationalDialPrefix);
                            setFieldValue('FromPBXNationalPrefix', fullCountry.DefaultNationalDialPrefix);
                            setFieldValue('ToPBXInternationalPrefix', fullCountry.DefaultInternationalDialPrefix);
                            setFieldValue('ToPBXNationalPrefix', fullCountry.DefaultNationalDialPrefix);
                        };

                        var p = [];
                        if (values['CountryId']) {
                            // mimic a state response
                            p.push(new Promise(function (resolve, reject) {
                                resolve({
                                    'data': {
                                        'Results': [{ CountryId: values['CountryId'] }]
                                    }
                                });
                            }));
                        } else if (values['StateId']) {
                            p.push( cmsGetStatePromise(self.props.account.Id, { 'stateId': values['StateId'] }));
                        }

                        // Go from state response to country object to then get dial default values
                        Promise.all(p).then(function (results) {
                            if (results) {
                                var data = results[0];
                                if (data && data.data && data.data.Results) {
                                    var tempState = data.data.Results[0];
                                    cmsGetCountriesPromise(self.props.account.Id, { 'countryId': tempState.CountryId }).then(function (data) {
                                        if (data && data.data && data.data.Results) {
                                            var fullCountry = data.data.Results[0];
                                            handleCountryCb(fullCountry);
                                        }
                                    });
                                }
                            }
                        });
                     }
                },
                Values: [
                    { label: 'E164 with +', value: 'E164WithPlus' },
                    { label: 'E164 without +', value: 'E164WithoutPlus' },
                    { label: 'Localized', value: 'Localized' },
                ]
            },
            {
                Id: 'FromPBXInternationalPrefix',
                Label: 'Inbound International Prefix',
                Group: 'e164from',
                ViewOrder: 50,
                VisibilityKey: 'DialingPrefixes',
                Type: 'text'
            },
            {
                Id: 'FromPBXNationalPrefix',
                Label: 'Inbound National Prefix',
                ViewOrder: 50,
                VisibilityKey: 'DialingPrefixes',
                Group: 'e164from',
                Type: 'text'
            },
            {
                Id: 'ToPBXInternationalPrefix',
                Label: 'Outbound International Prefix',
                ViewOrder: 50,
                VisibilityKey: 'DialingPrefixes',
                Group: 'e164to',
                Type: 'text'
            },
            {
                Id: 'ToPBXNationalPrefix',
                Label: 'Outbound National Prefix',
                ViewOrder: 50,
                VisibilityKey: 'DialingPrefixes',
                Group: 'e164to',
                Type: 'text'
            }
        ]);
        return out;
    }

    getNameCountryFormKeys() {

        var fields = [];

        var nameField = {
            Id: 'Name',
            Label: 'Service Name',
            Type: (this.props.existingCount === 0 ? 'hidden' : 'text'),
            Group: 'NameCountry',
            HelpTitle: 'Service Name Help',
            Help: (
                <>
                    <p>The Service Name field is restricted to alphanumeric characters, punctuation, spaces and the special characters "|" and "+".</p>
                </>
            ),
        };

        var extraId = null;

        if (this.props.hasRole('ExtendedUI')) {
            extraId = <><br /><span>Account ID: {this.props.account.Id}, Service Id: {this.props.currentService ? this.props.currentService.Id : 'N/A'}</span></>;
        }

        if (this.props.pbxTemplate) {
            nameField['Tooltip'] = <span>Template: {this.props.pbxTemplate.Name} {extraId}</span>;
        }

        fields.push(nameField);

        var currentVariant = this.getVariantObj();
        if (currentVariant.RequireCountryOrState) {
            fields.push({
                Id: "CountryId",
                Label: 'Country',
                Type: 'country',
                Group: 'NameCountry'
            });

            fields.push({
                Id: "StateId",
                Label: 'State / Province',
                Type: 'state',
                Group: 'NameCountry',
                HiddenOnly: true
            });
        }

        return fields;
    }

    addFormKeyState(fields) {
        var self = this;
        fields.forEach(function (obj) {
            if (obj.State) {
                // Skip any pre-defined state cases...
                return;
            }

            var k = obj.Id;

            var state = null;

            if (self.props.pbxTemplate) {
                var vk = k;
                if (obj.VisibilityKey) {
                    vk = obj.VisibilityKey;
                }
                state = self.props.pbxTemplate.Visibility[vk];

                if (self.props.pbxTemplate.Labels.hasOwnProperty(k)) {
                    obj.Label = self.props.pbxTemplate.Labels[k];
                }
            }

            if (!state) {
                // Default to normal editable-field
                state = obj.hasOwnProperty('PresetState') ? obj.PresetState : 'Edit';
            }

            if (obj.hasOwnProperty('ForceState') && obj.ForceState) {
                state = obj.ForceState;
            }

            if (state === 'Hide' || state === 'View') {
                if (self.state.showHiddenFields) {
                    if (obj.hasOwnProperty('DisableOverride') && obj.DisableOverride) {
                        // skip, this is a field that is specifically not suitable to display
                    } else {
                        state = 'LockedEditOptional';
                    }
                } 
            }

            obj.State = state;
        });

        return fields;
    }

    toggleOwnerHiddenFields() {
        this.setState(prevState => ({ showHiddenFields: !prevState.showHiddenFields }));
    }

    getGlobalPBXUpgradeDialog(values, setFieldValue) {
        var self = this;
        var val = values['UseGlobalRegistration'];
        return (
            <>
                <Dialog
                    hidden={!self.state.showGlobalPBXUpgradeDialog}
                    onDismiss={function () {
                        if (val.includes('GlobalRegistration')) {
                            var nextValue = val.filter(
                                value => value !== 'GlobalRegistration'
                            );
                            setFieldValue("UseGlobalRegistration", nextValue[0]);
                            self.setState({ showGlobalPBXUpgradeDialog: false });
                        }
                    }}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Use Global PBX',
                        showCloseButton: false
                    }}
                    modalProps={{
                        styles: { main: { maxWidth: 450 } },
                    }}
                >
                    <div>
                        <div>
                            <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
                                Please note, you will require a Global Administrator
                                and an unassigned license during the next sync to complete
                                this upgrade.
                            </MessageBar>
                            <br />
                            <p>Are you sure you want to continue?</p>
                            <DialogFooter>
                                <PrimaryButton onClick={function () {
                                    self.setState({ showGlobalPBXUpgradeDialog: false });
                                }} text="Yes" />
                                <DefaultButton onClick={function () {
                                    setFieldValue("UseGlobalRegistration", ['']);
                                    self.setState({ showGlobalPBXUpgradeDialog: false });
                                }} text="No" />
                            </DialogFooter>
                        </div>
                    </div>
                </Dialog>
            </>
        );
    }

    getHiddenFieldToggleButton(isSubmitting, values) {
        var self = this;
        var randomId = Math.floor((Math.random() * 10000) + 1);
        var revealButton =
            <>
                <a
                    className="btn btn-link btn--faux-link"
                    style={{ marginRight: '5px' }}
                    onClick={(e) => { e.preventDefault(); if (!values.IsUIDisabled) { self.toggleOwnerHiddenFields(); } return false; }}
                    disabled={isSubmitting || values.IsUIDisabled}
                >
                    {self.state.showHiddenFields ? 'Hide Hidden Fields' : 'Show Hidden Fields'}
                </a>
                <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); self.toggleHiddenHelp(); return false; }}>
                    <i className="fa-solid fa-question-circle" id={"HiddenFieldsHelp" + randomId}></i>
                </button>
                {self.state.showHiddenFieldsHelp ?
                    <TeachingBubble
                        target={'#HiddenFieldsHelp' + randomId}
                        hasCondensedHeadline={true}
                        onDismiss={() => self.dismissHiddenHelp()}
                        hasCloseIcon={true}
                        closeButtonAriaLabel="Close"
                        headline={"Hidden Field Display"}
                    >
                        <p>Show fields usually hidden by the template. You can then make edits for this specific service.</p>
                        <p>You are seeing this because you are the owner of the '{self.props.pbxTemplate.Name}' template.</p>
                    </TeachingBubble>
                    : null}
            </>
        return revealButton;
    }

    getFormKeys(values) {
        var fields = [];

        fields = fields.concat(this.getNameCountryFormKeys());
        fields = fields.concat(this.getBaseFormKeys(false));
        fields = fields.concat(this.getPBXFormKeys(false, values));

        fields = this.addFormKeyState(fields);
        fields = _.sortBy(fields, "ViewOrder");
        return fields;
    }

    // Done separately to allow for future modal validation in an easy way
    validateTrunkNumbers(values) {
        let rangeError = [];

        if (values['NumberRanges'] && values['NumberRanges'].length === 0) {
            rangeError[0] = "You must enter at least one Number Range.";
        }
        if (values['NumberRanges']) {
            let re = /^\+\d+$/;
            _.forEach(values['NumberRanges'], function (range, i) {
                if (range.Start === '') {
                    rangeError[i] = "You must enter a start number for the range.";
                } else if (range.End === '') {
                    rangeError[i] = "You must enter an end number for the range.";
                } else if (re.exec(range.Start) === null) {
                    rangeError[i] = "You must enter a valid E164 start number.";
                } else if (re.exec(range.End) === null) {
                    rangeError[i] = "You must enter a valid E164 end number.";
                }
            });

        }

        var serviceNumberError = [];
        if (values.ServiceNumbers) {
            var phoneNumbers = [];
            for (var i = 0; i < values.ServiceNumbers.length; i++) {
                var entry = values.ServiceNumbers[i];
                phoneNumbers.push(entry.PhoneNumber);
                if (entry.PhoneNumber && !/^\+[0-9]+$/.test(entry.PhoneNumber)) {
                    serviceNumberError[i] = "Service Number must only include digits, and begin with a '+'";
                }
            }
            var uniqueArray = phoneNumbers.filter(getDistinctArrayValues);
            if (uniqueArray.length < phoneNumbers.length) {
                serviceNumberError[values['ServiceNumbers'].length - 1] = 'You cannot enter duplicate Phone Numbers';
            }
        }

        let errors = {};
        if (rangeError.length) {
            errors['NumberRanges'] = rangeError;
        }
        if (serviceNumberError.length) {
            errors['ServiceNumbers'] = serviceNumberError;

        }
        return errors;
    }

    validateBulkImportRanges(values) {
        var ranges = [];
        if (values.NumberRangesBulkImport) {
            ranges = values.NumberRangesBulkImport.split('\n');
        }
        let rangeError = [];
        let re = /^\+\d+$/;

        ranges.forEach(function (range, i) {
            var start = null;
            var end = null;

            var individualNumbers = range.split(',');
            if (individualNumbers[0]) {
                start = individualNumbers[0].replace(' ', '');
            }
            if (individualNumbers[1]) {
                end = individualNumbers[1].replace(' ', '');
            }

            if (start && !end) { end = start; }
            var startInt = start.replace('+', '').replace(' ', '');
            var endInt = end.replace('+', '').replace(' ', '');
            var isStartInt = !isNaN(startInt);
            var isEndInt = !isNaN(endInt);

            if (!start) {
                rangeError[i] = "You must enter a start number for the range.";
            } else if (re.exec(start) === null) {
                rangeError[i] = "You must enter a valid E164 start number.";
            } else if (end && re.exec(end) === null) {
                rangeError[i] = "You must enter a valid E164 end number.";
            } else if (!isStartInt || !isEndInt) {
                rangeError[i] = "Values must be numeric.";
            } else if (isStartInt && isEndInt && endInt < startInt) {
                rangeError[i] = "The end number must be greater than the start number.";
            }
        });

        let errors = {};
        if (rangeError.length) {
            errors['NumberRangesBulkImport'] = rangeError;
        }
        return errors;
    }

    validateTrunkValues(values) {
        var self = this;
        let errors = self.validatePBXValues(values);

        if(!values["Emergency"])
        {
            _.merge(errors, self.validateTrunkNumbers(values));
        }

        _.merge(errors, self.validateBulkImportRanges(values));

        if (checkWillRegister(values['FauxDoRegistration']) || checkHasCredentials(values['FauxDoRegistration'])) {
            if (values['AuthPassword'] === '') {
                if (!self.props.currentService || !self.props.currentService.TrunkSettings.HasPassword) {
                    errors.AuthPassword = 'You must enter a password';
                }
            }
            if (values['Identifier'] === '') {
                errors.Identifier = 'You must enter a username';
            }
        }

        return errors;
    }

    validatePBXValues(values) {
        var self = this;
        let errors = {};

        let pbxMappings = [
            "teams_available_response",
            "teams_busy_response",
            "teams_inacall_response",
            "teams_presenting_response",
            "teams_donotdisturb_response",
            "teams_berightback_response",
            "teams_away_response",
            "teams_offline_response",
            "teams_appearOffline_response"
        ];

        if (!values.Name) {
            errors.Name = 'You must enter a name for this service.';
        } else if (!isValidWord(values.Name)) {
            errors.Name = 'You must enter a valid name for this service.';
        }

        if (!values.ServiceVariantId) {
            errors.ServiceVariantId = 'You must select a variant.';
        }

        if (!values.CountryId && !values.StateId) {
            errors.CountryId = 'You must select a country.';
        }

        for (let i = 0; i < pbxMappings.length; i++) {
            let testVal = values[pbxMappings[i]];
            if (testVal && testVal.value) {
                let correctFormat = /^\d\d\d [a-zA-Z ]+/gm.test(testVal.value);
                if (!correctFormat) {
                    errors[pbxMappings[i]] = 'The response must be of the form "$Code Message", where $Code is a three digit number.';
                }
            }
        }

        /**
                 * IP check 1:
                 * IPs always required for non-reg
                 **/
        var ipError = [];
        if (values['FauxDoRegistration'].includes('SingleNonRegistration')
            || values['FauxDoRegistration'].includes('RedundantNonRegistration')
        ) {

            if (values['AllowedIPs'].length === 0
                || (values['AllowedIPs'].length === 1 && values['AllowedIPs'][0] === "")
            ) {
                ipError[0] = "You must enter at least one IP.";
            }
        }

        /**
         * IP check 2:
         * If we do have some real input, not just placeholder empty string, then validate if it's a real IP
         * Separate to above as we do see cases of Registration also with allowedIPs array
         **/
        if (    values['AllowedIPs'].length > 1
            || (values['AllowedIPs'].length === 1 && values['AllowedIPs'][0] !== "")
        ) {
            _.forEach(values['AllowedIPs'], function (ip, i) {
                var result = checkIp(ip);
                if (!result.isValid) {
                    ipError[i] = "You must enter a valid IP address.";
                } else if (!result.isPublicIp) {
                    ipError[i] = "You must enter a public IP address.";
                }
            });
        }

        if (ipError.length) {
            errors.AllowedIPs = ipError;
        }

        if (self.props.pbxTemplate) {
            var FormKeys = self.getFormKeys(values);
            FormKeys.forEach(function (obj) {
                if (obj.Id === 'FauxDoRegistration') {
                    if (values[obj.Id] === '') {
                        errors[obj.Id] = "You must select a registration state";
                        return;
                    }
                }
                if (obj.State === 'Hidden' || obj.HiddenOnly) {
                    // We don't user validate hidden only fields
                    return;
                }

                var state = obj.State;
                if (state.indexOf('Mandatory') > -1) {
                    if (obj.Id === 'FromPBXInternationalPrefix'
                        || obj.Id === 'FromPBXNationalPrefix') {
                        // ignore, we copy values from 'To' fields...
                    } else if (values[obj.Id] === '') {
                        if (obj.Type === 'select') {
                            errors[obj.Id] = 'You must select a value.';
                        } else {
                            errors[obj.Id] = 'You must provide a ' + obj.Label + ' value.';
                        }
                        return;
                    }
                }
               
                if (values[obj.Id] !== '') {
                    if (obj.Id === 'Realm') {
                        var error = ValidateRealm(values[obj.Id], obj.Label);
                        if (error) {
                            errors[obj.Id] = error;
                        }
                    } else if (obj.Id === 'Proxy') {
                        var error = ValidateProxy(values[obj.Id], obj.Label);
                        if (error) {
                            errors[obj.Id] = error;
                        }
                    }
                }
            });
        }
        if(showConsoleLogs()) {
            console.log("Errors for pbx/trunk service form:");
            console.log(errors);
        }

        var edgeSBCFlags = values['EdgeSBCFlags'];
        if (edgeSBCFlags.length > 0 && (!isJson(edgeSBCFlags) || edgeSBCFlags.length > 4000)) {
            errors.EdgeSBCFlags = 'The Edge SBC Flags must be valid JSON and should not exceed 4000 characters.';
        }

        return errors;
    }

    getSRV(values) {
        var self = this;
        // Disable for now until SRV usage ready
        if (self.props.currentService && self.props.currentService.PBXSettings) {
            if (self.props.currentService.PBXSettings.SRVRecord && values && !checkWillRegister(values['FauxDoRegistration'])) {
                return (
                    <p>
                        To send calls in to the platform use the following SRV hostname in the Request URI: {self.props.currentService.PBXSettings.SRVRecord}
                        <button className="btn btn-link btn--faux-link btn-sbc-help" onClick={(e) => { e.preventDefault(); self.toggleSRVHelp(); return false; }}>
                            <i className="fa-solid fa-question-circle" id="SRVHelp"></i>
                        </button>
                        {self.state.showSRVHelp ?
                            <TeachingBubble
                                target={'#SRVHelp'}
                                hasCondensedHeadline={true}
                                onDismiss={() => self.dismissSRVHelp()}
                                hasCloseIcon={true}
                                closeButtonAriaLabel="Close"
                                headline={"Routing SRV Hostname"}
                            >
                                <p>This can be used instead of the SBC IP addresses
                                to route calls to us. If you are using TLS
                                ensure that your PBX or Trunk supports wildcard
                                SSL certificates.</p>
                            </TeachingBubble>
                        : null}
                    </p>
                );
            }
        }
        return null;
    }

    getDedicatedIps()
    {       
        var self = this;

        if ( self.props.currentService && self.props.currentService.DedicatedIps && self.props.currentService.DedicatedIps.length > 0) {
            
            const dedicatedIps = isSystemOwner(self.props.baseAccountInfo.Roles) 
                ? self.props.currentService.DedicatedIps.map(x => x.IPAddress + ' (' + x.HostName + ')').join(', ')
                : self.props.currentService.DedicatedIps.map(x => x.IPAddress).join(', ');

            var sbcListOut = (
                <CopyToClipboard text={dedicatedIps} onCopy={() => self.justCopiedAction(dedicatedIps)}>
                    <button onClick={(e) => e.preventDefault()} className="btn btn-link">
                        {dedicatedIps} <i className="fa-solid fa-copy"></i>
                    </button>
                </CopyToClipboard>
            );

            return (
                <>
                    <p>The following dedicated IPs are assigned to this service: {sbcListOut}
                        <button className="btn btn-link btn--sbc-ui-list btn--faux-link btn-sbc-help" onClick={(e) => { e.preventDefault(); self.toggleDedicatedIPHelp(); return false; }}>
                            <i className="fa-solid fa-question-circle" id="DedicatedIPHelp"></i>
                        </button>
                        {self.state.showDedicatedIPHelp ?
                            <TeachingBubble
                                target={'#DedicatedIPHelp'}
                                hasCondensedHeadline={true}
                                onDismiss={() => self.dismissDedicatedIPHelp()}
                                hasCloseIcon={true}
                                closeButtonAriaLabel="Close"
                                headline={"SBC IP Addresses"}
                            >
                                <p>If you filter SIP signalling traffic based
                                    on source IP addresses you will need to add all
                                    of the listed IPs here to the appropriate
                                    whitelist. Media traffic may originate from
                                    additional IP addresses to those listed.</p>
                            </TeachingBubble>
                            : null}
                    </p>
                </>
                );

        }
    }

    getSBCs() {
        var self = this;
        if (self.props.currentService) {
            if (self.props.currentService.SBCs && self.props.currentService.SBCs.length > 0) {
                var sbcList = [];
                var text = '';

                // Show main IP (active/registering) first
                var tempSbc = self.props.currentService.SBCs;
                tempSbc = _.sortBy(tempSbc, function (s) {
                    if (s.IsRegistration && s.IsActive) {
                        return 1;
                    } else if (s.IsActive) {
                        return 2;
                    } else {
                        return 3;
                    }
                });

                tempSbc.forEach(function (sbc, idx, array) {
                    var sep = (idx === array.length - 1)  ? '' : ', ';
                    var titleStr = sbc.IsActive ? "Active SBC. " : "Inactive SBC. ";
                    titleStr += sbc.IsRegistration ? "Registration Enabled. " : "";
                    sbcList.push(
                        <span key={sbc.IPAddress} title={titleStr}>{sbc.IPAddress}:{sbc.Port}{sep}</span>
                    );
                    text += sbc.IPAddress + ":" + sbc.Port + sep;
                })

                var sbcListOut = (
                    <CopyToClipboard text={text} onCopy={() => self.justCopiedAction(text)}>
                        <button onClick={(e) => e.preventDefault()} className="btn btn-link btn--multi-line-text">
                            {sbcList} <i className="fa-solid fa-copy"></i>
                        </button>
                    </CopyToClipboard>
                );

                var ranges = _.uniq(
                    _.map(
                        _.filter(self.props.currentService.SBCs, function(s) {
                            return s && s.IPRange
                        }),
                        function(s) {
                            return s.IPRange
                        }
                    )
                ).sort();

                var sbcCustomerText = null;
                if (ranges) {
                    sbcCustomerText = (
                        <>
                        <p>The following IP {ranges.length === 1 ? "range is" : "ranges are"} assigned to this service: {ranges.join(', ')}</p>
                        <p>Current SBCs:&nbsp;
                            {_.sortBy(self.props.currentService.SBCs, 'SBC').map(function(s, idx, arr) {
                                var parts = [];
                                // Always show SBC name and info popup.
                                parts.push( s.SBC.split('.',2)[0].toLowerCase() );

                                // Registration, show which SBC is 'live'...
                                if (s.IsRegistration && s.IsActive) {
                                    parts.push('(Active Registration)');
                                }

                                // Trunk and non-registering, default to show
                                // IPs, they are likely to need it.
                                if ( self.props.currentService.Variant
                                        && self.props.currentService.PBXSettings.SBCMode
                                        && self.props.currentService.PBXSettings.SBCMode.includes('NonRegistration')
                                ) {
                                    parts.push( '(' + s.IPAddress + ':' + s.Port + ')' );
                                }

                                return (
                                    <span key={s.SBC}>
                                        {parts.map((v) => <span className="sbc-list-space">{v}</span>)}
                                        <SBCNameHelp SBC={s.SBC} IPAddress={s.IPAddress} IPRange={s.IPRange} />
                                        {idx + 1 < arr.length ? ', ' : null}
                                    </span>
                                );
                            })}
                            </p>
                        </>
                    );
                } else {
                    // Legacy, going away when we have all ranges
                    sbcCustomerText = (
                        <p>The following SBCs are assigned to this service: {sbcListOut}
                            <button className="btn btn-link btn--sbc-ui-list btn--faux-link btn-sbc-help" onClick={(e) => { e.preventDefault(); self.toggleSBCIPHelp(); return false; }}>
                                <i className="fa-solid fa-question-circle" id="SBCIPHelp"></i>
                            </button>
                            {self.state.showSBCIPHelp ?
                                <TeachingBubble
                                    target={'#SBCIPHelp'}
                                    hasCondensedHeadline={true}
                                    onDismiss={() => self.dismissSBCIPHelp()}
                                    hasCloseIcon={true}
                                    closeButtonAriaLabel="Close"
                                    headline={"SBC IP Addresses"}
                                >
                                    <p>If you filter SIP signalling traffic based
                                        on source IP addresses you will need to add all
                                        of the listed IPs here to the appropriate
                                        whitelist. Media traffic may originate from
                                        additional IP addresses to those listed.</p>
                                </TeachingBubble>
                                : null}
                        </p>
                    );
                }

                return (
                    <>
                        {sbcCustomerText}
                        &nbsp;
                        &nbsp;
                        {isSystemOwner(self.props.baseAccountInfo.Roles) &&
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <SbcReallocateDropDown
                                        serviceId={self.props.currentService.Id}
                                        onSubmit={self.props.updateFullServiceTrigger}
                                        IsUIDisabled={self.props.currentService ? !self.props.currentService.AllowEditInUI : false}
                                    />
                                </div>
                            </div>
                        }
                    </>
                );
            }
        }

        return null;
    }

    getEdgeSBCFlags(values, errors, touched, setFieldValue) {

        if (!isSystemOwner(this.props.baseAccountInfo.Roles)) {
            return null;
        }

        var hasError = errors["EdgeSBCFlags"] != null ? 'error' : '';
        var edgeSBCFlagsObj =
        {
            Id: "EdgeSBCFlags",
            Label: 'Edge SBC Flags',
            Type: 'text',
            HelpTitle: 'Edge SBC Flags',
            Help: (
                <p>Edge SBC Flags are used to alter calling behaviour.</p>
            ),
            State: 'Locked'
        };

        var edgeSBCFlags =

            <div className='row'>
                <div className='col-sm-12'>
                    <div className="system-owner-container alert">
                        <div key={edgeSBCFlagsObj.Id} className={"form-group " + hasError}>
                            <FieldWithDisposition
                                visibility={edgeSBCFlagsObj.State}
                                formObject={edgeSBCFlagsObj}
                                formValues={values}
                                errors={errors}
                                touched={touched}
                                template={(this && this.props && this.props.pbxTemplate) ? this.props.pbxTemplate : null}
                                setFieldValue={setFieldValue}
                                account={(this && this.props && this.props.account) ? this.props.account : null}
                            />
                        </div>
                    </div>
                </div>
            </div>

        return edgeSBCFlags;
    }

    renderIsEmergencyService = () => {
        if (this.props.pbxTemplate && this.props.pbxTemplate.EmergencyAccountServiceId != null && this.props.pbxTemplate.EmergencyAccountServiceId.length > 0) {
            return <p>This service has an <b>Emergency </b>trunk configured.</p>;
        }

        return null;
    }

    renderDedicatedIpTag = () => {
        const ipTag = this.props.pbxTemplate ? this.props.pbxTemplate.DedicatedIPTag : null;
        if (ipTag != null && ipTag.length > 0) {
            let content = 'This service is set to use a dedicated IP';
            if (isSystemOwner(this.props.baseAccountInfo.Roles)) {
                content = `${content} of ${ipTag}`;
            }
            return <p className='mb-2'>{content}</p>;
        }
        return null;
    }

    getPBXFormDefaults(isTrunk) {
        var fauxReg = this.formInputDefault('PBXSettings', 'SBCMode');
        // For trunks, the 'special case' is no registration _and_ no credentials
        if (   isTrunk
            && fauxReg
            && this.props.pbxTemplate
            && this.props.pbxTemplate.Visibility
            && this.props.pbxTemplate.Visibility.Credentials
            && this.props.pbxTemplate.Visibility.Credentials.indexOf('Hide') < 0
            && (fauxReg == 'SingleNonRegistration' || fauxReg == 'RedundantNonRegistration')
        ) {
            if (   !this.props.currentService
                || this.props.currentService && this.props.currentService.TrunkSettings.Identifier
            ) {
                fauxReg += "-WithCreds";
            }
        } 

        var baseDefaults = {
            accountId: this.props.account.Id,
            Name: this.getPbxName(),
            ServiceId: this.getServiceId(),
            ServiceVariantId: this.getVariantId(),
            FauxDoRegistration: fauxReg,
            IsPresenceEnabled: false,

            Id: (this.props.currentService && this.props.currentService.Id
                ? this.props.currentService.Id : ''),

            AccountPBXTemplateId: this.props.pbxTemplate && this.props.pbxTemplate.hasOwnProperty('Id') ? this.props.pbxTemplate.Id : '',
            ServiceSyncModuleId: this.formServiceInputDefault('ServiceSyncModuleId'),
            SyncEnabled: this.formServiceInputDefault('SyncEnabled', true),

            AllowedCodecs: this.formInputDefault('PBXSettings', 'AllowedCodecs', []),
            EncryptMedia: this.formInputDefault('PBXSettings', 'EncryptMedia'),
            Expiry: this.formInputDefault('PBXSettings', 'Expiry'),
            Protocol: this.formInputDefault('PBXSettings', 'Protocol'),
            Proxy: this.formInputDefault('PBXSettings', 'Proxy'),
            Realm: this.formInputDefault('PBXSettings', 'Realm'),
            TransferMode: this.formInputDefault('PBXSettings', 'TransferMode'),
            SuppressContactDataParam: this.formInputDefault('PBXSettings', 'SuppressContactDataParam'),
            SBCMode: this.formInputDefault('PBXSettings', 'SBCMode'),

            FromHeaderType: this.formInputDefault('PBXSettings', 'FromHeaderType'),
            PAIHeaderType: this.formInputDefault('PBXSettings', 'PAIHeaderType'),
            ConnectionConcurrency: this.formInputDefault('PBXSettings', 'ConnectionConcurrency'),
            E164Mode: this.formInputDefault('PBXSettings', 'E164Mode'),
            OutsideLinePrefix: this.formInputDefault('PBXSettings', 'OutsideLinePrefix'),
            FromPBXInternationalPrefix: this.formInputDefault('PBXSettings', 'FromPBXInternationalPrefix'),
            FromPBXNationalPrefix: this.formInputDefault('PBXSettings', 'FromPBXNationalPrefix'),
            ToPBXInternationalPrefix: this.formInputDefault('PBXSettings', 'ToPBXInternationalPrefix'),
            ToPBXNationalPrefix: this.formInputDefault('PBXSettings', 'ToPBXNationalPrefix'),
            EdgeSBCFlags: this.formInputDefault('PBXSettings', 'EdgeSBCFlags'),
            UseGlobalRegistration: [''],
            IsUIDisabled: this.props.currentService ? !this.props.currentService.AllowEditInUI : false,

            AllowedIPs: this.formInputDefault('PBXSettings', 'AllowedIPs', ['']),

            // Defaults only. Real values handled separately below
            CountryId: '',
            StateId: '',
        };

        if (baseDefaults['AllowedCodecs'].length > 0) {
            baseDefaults['AllowedCodecs'] = expandCodecs(baseDefaults['AllowedCodecs']);
        }

        baseDefaults = this.addCountryStateValues(baseDefaults);

        var fullPolicy = this.formInputDefault('PBXSettings', 'CallingPolicy');
        baseDefaults["DefaultCallingPolicy"] = [];
        baseDefaults["OriginalCallingPolicy"] = fullPolicy;
        baseDefaults['VMCallingPolicy'] = 'VM';
        baseDefaults['MOHCallingPolicy'] = 'MOHTEAMS';
        if (fullPolicy) {
            var temp = fullPolicy.split('_');
            var vm = temp[0];
            var moh = temp[1];

            baseDefaults['VMCallingPolicy'] = vm;
            baseDefaults['MOHCallingPolicy'] = moh;

            if (vm && moh) {
                baseDefaults["DefaultCallingPolicy"] = ['custom'];
            }
        }
        if (this.props.currentService && this.props.currentService.PBXSettings && this.props.currentService.PBXSettings.hasOwnProperty('ApplyPAIDPrefix')) {
            if (this.props.currentService.PBXSettings.ApplyPAIDPrefix) {
                baseDefaults['ApplyPAIDPrefix'] = ["", "checked"];
            } else {
                baseDefaults['ApplyPAIDPrefix'] = [""];
            }
        } else if (this.props.pbxTemplate && this.props.pbxTemplate.hasOwnProperty('ApplyPAIDPrefix')) {
            if (this.props.pbxTemplate.ApplyPAIDPrefix) {
                baseDefaults['ApplyPAIDPrefix'] = ["", "checked"];
            } else {
                baseDefaults['ApplyPAIDPrefix'] = [""];
            }
        }
        /**
         Section for PBX mapping
         */
        if (!isTrunk) {
            baseDefaults['TeamsToPBXStateMapping'] = this.formInputDefault('PBXSettings', 'TeamsToPBXStateMapping')
                ? this.formInputDefault('PBXSettings', 'TeamsToPBXStateMapping')
                : null;
            baseDefaults['IsPresenceEnabled'] = this.formInputDefault('PBXSettings', 'IsPresenceEnabled')
                ? this.formInputDefault('PBXSettings', 'IsPresenceEnabled')
                : false;
            baseDefaults['EnforceRegionCDRs'] = this.formInputDefault('PBXSettings', 'EnforceRegionCDRs')
                ? this.formInputDefault('PBXSettings', 'EnforceRegionCDRs')
                : false;

            baseDefaults["teams_available"] = null;
            baseDefaults["teams_busy"] = null;
            baseDefaults["teams_inacall"] = null;
            baseDefaults["teams_presenting"] = null;
            baseDefaults["teams_donotdisturb"] = null;
            baseDefaults["teams_berightback"] = null;
            baseDefaults["teams_away"] = null;
            baseDefaults["teams_offline"] = null;
            baseDefaults["teams_appearOffline"] = null;

            baseDefaults["teams_available_response"] = null;
            baseDefaults["teams_busy_response"] = null;
            baseDefaults["teams_inacall_response"] = null;
            baseDefaults["teams_presenting_response"] = null;
            baseDefaults["teams_donotdisturb_response"] = null;
            baseDefaults["teams_berightback_response"] = null;
            baseDefaults["teams_away_response"] = null;
            baseDefaults["teams_offline_response"] = null;
            baseDefaults["teams_appearOffline_response"] = null;

            baseDefaults["PresenceDelivery"] = null;
            baseDefaults['TeamsToPBXStateMapping'] = this.props.currentService && this.props.currentService.PBXSettings && this.props.currentService.PBXSettings.TeamsToPBXStateMapping ? JSON.parse(this.props.currentService.PBXSettings.TeamsToPBXStateMapping) : null;
            if (baseDefaults['TeamsToPBXStateMapping'] && baseDefaults['TeamsToPBXStateMapping'].length > 0) {
                baseDefaults['TeamsToPBXStateMapping'].forEach(function (entry) {
                    var keys = Object.keys(entry);
                    var statusName = keys.find(x => x != 'rejectCalls');

                    if (statusName === 'PresenceDelivery') {
                        baseDefaults["PresenceDelivery"] = entry[statusName];
                    } else {
                        var fieldName = 'teams_' + statusName;
                        let fieldType = fieldName + '_response';
                        let responseVal = entry['rejectCalls'] ? entry['rejectCalls'] : null;
                        let mappingVal = entry[statusName] ? entry[statusName] : null;
                        if (responseVal) {
                            if (typeof (responseVal) == 'string' && responseVal.includes('{')) {
                                responseVal = JSON.parse(responseVal)
                            }
                        }
                        if (mappingVal) {
                            if (typeof (mappingVal) == 'string' && mappingVal.includes('{')) {
                                mappingVal = JSON.parse(mappingVal)
                            }
                        }
                        baseDefaults[fieldName] = mappingVal;
                        baseDefaults[fieldType] = responseVal;
                    }
                })
            }
        }
        return baseDefaults;
    }

    resetPBXSIPMapping(values, setFieldValue) {
        let mappings = this.props.currentService && this.props.currentService.PBXSettings && this.props.currentService.PBXSettings.TeamsToPBXStateMapping ? JSON.parse(this.props.currentService.PBXSettings.TeamsToPBXStateMapping) : null;
        if (mappings && mappings.length > 0) {
            mappings.forEach(function (entry) {
                var keys = Object.keys(entry);
                var statusName = keys.find(x => x != 'rejectCalls');

                if (statusName === 'PresenceDelivery') {
                    setFieldValue("PresenceDelivery", entry[statusName]);
                } else {
                    var fieldName = 'teams_' + statusName;
                    let fieldType = fieldName + '_response';
                    let responseVal = entry['rejectCalls'] ? entry['rejectCalls'] : null;
                    let mappingVal = entry[statusName] ? entry[statusName] : null;
                    if (responseVal) {
                        if (typeof (responseVal) == 'string' && responseVal.includes('{')) {
                            responseVal = JSON.parse(responseVal)
                        }
                    }
                    if (mappingVal) {
                        if (typeof (mappingVal) == 'string' && mappingVal.includes('{')) {
                            mappingVal = JSON.parse(mappingVal)
                        }
                    }
                    setFieldValue(fieldName, mappingVal);
                    setFieldValue(fieldType, responseVal);
                }
            })
        } else {
            setFieldValue("teams_available", null);
            setFieldValue("teams_busy", null);
            setFieldValue("teams_inacall", null);
            setFieldValue("teams_presenting", null);
            setFieldValue("teams_donotdisturb", null);
            setFieldValue("teams_berightback", null);
            setFieldValue("teams_away", null);
            setFieldValue("teams_offline", null);
            setFieldValue("teams_appearOffline", null);

            setFieldValue("teams_available_response", null);
            setFieldValue("teams_busy_response", null);
            setFieldValue("teams_inacall_response", null);
            setFieldValue("teams_presenting_response", null);
            setFieldValue("teams_donotdisturb_response", null);
            setFieldValue("teams_berightback_response", null);
            setFieldValue("teams_away_response", null);
            setFieldValue("teams_offline_response", null);
            setFieldValue("teams_appearOffline_response", null);

            setFieldValue("PresenceDelivery", null);
        }
        return values;
    }

    displayMisconfigurationWarnings(values) {
        var self = this;

        if (self.props.canSelectTrueTransfer && !self.props.trueTransferSupported && values['TransferMode'] === 'All') {
            toast.warning("Upgrade to OneClick 2nd Gen for Teams True Transfer support");
        }
    }

    handleSubmitPBXValues(isTrunk, values) {
        var self = this;
        // Collapse down country object to single ID value
        var variant = self.getVariantObj();
        if (variant.RequireCountryOrState) {
            //collapseToCountryOrStateId(values);
            if (values.CountryId === '') {
                delete values.CountryId;
            }
            if (values.StateId === '') {
                delete values.StateId;
            }
        } else {
            delete values.CountryId;
            delete values.StateId;
        }

        // Move PBX specific settings to live under PBXSettings entry in post data
        var FormKeys = self.getPBXFormKeys(isTrunk, values);
        values['PBXSettings'] = {
            AccountPBXTemplateId: values['AccountPBXTemplateId'],
            EdgeSBCFlags: values['EdgeSBCFlags']
        };

        delete values['EdgeSBCFlags']
        delete values['AccountPBXTemplateId'];

        // Ditch default AllowedIPs...
        if (values['AllowedIPs'] && values['AllowedIPs'].length === 1 && values['AllowedIPs'][0] === "") {
            values['AllowedIPs'] = [];
        }

        if (values['AllowedCodecs']) {
            values['AllowedCodecs'] = _.map(values['AllowedCodecs'], function (o) { return o.value; });
        }

        if (checkHasCredentials(values['FauxDoRegistration'])) {

            var newval = values['FauxDoRegistration'].replace('-WithCreds', '');
            values['SBCMode'] = newval;
        } else {
            values['SBCMode'] = values['FauxDoRegistration'];
        }

        if (values['UseGlobalRegistration'] && values['UseGlobalRegistration'].includes('GlobalRegistration')) {
            values['SBCMode'] = 'GlobalRegistration';
        }

        // Both Temp UI values, doesn't mean anything to the API
        delete values['FauxDoRegistration'];
        delete values['UseGlobalRegistration'];

        // Specipal case for ToPBX/FromPBX fields
        var duplicateValueHelper = false;
        if (duplicateValueHelper) {
            if (values['ToPBXInternationalPrefix'] && !values['FromPBXInternationalPrefix']) {
                values['FromPBXInternationalPrefix'] = values['ToPBXInternationalPrefix'];
            }
            if (values['ToPBXNationalPrefix'] && !values['FromPBXNationalPrefix']) {
                values['FromPBXNationalPrefix'] = values['ToPBXNationalPrefix'];
            }
        }

        var hasLocalE164 = (values['E164Mode'] && values['E164Mode'] === 'Localized') ? true : false;

        if (values.hasOwnProperty('TeamsToPBXStateMapping')) {

            var mappings = [
                {
                    "available": values["teams_available"],
                    "rejectCalls": values["teams_available_response"]
                },
                {
                    "busy": values["teams_busy"],
                    "rejectCalls": values["teams_busy_response"]
                },
                {
                    "inacall": values["teams_inacall"],
                    "rejectCalls": values["teams_inacall_response"]
                },
                {
                    "presenting": values["teams_presenting"],
                    "rejectCalls": values["teams_presenting_response"]
                },
                {
                    "donotdisturb": values["teams_donotdisturb"],
                    "rejectCalls": values["teams_donotdisturb_response"]
                },
                {
                    "berightback": values["teams_berightback"],
                    "rejectCalls": values["teams_berightback_response"]
                },
                {
                    "away": values["teams_away"],
                    "rejectCalls": values["teams_away_response"]
                },
                {
                    "offline": values["teams_offline"],
                    "rejectCalls": values["teams_offline_response"]
                },
                {
                    "appearOffline": values["teams_appearOffline"],
                    "rejectCalls": values["teams_appearOffline_response"]
                },
                {
                    "PresenceDelivery": values["PresenceDelivery"]
                }
            ];
            values['TeamsToPBXStateMapping'] = JSON.stringify(mappings);

            delete values["teams_available"];
            delete values["teams_busy"];
            delete values["teams_inacall"];
            delete values["teams_presenting"];
            delete values["teams_donotdisturb"];
            delete values["teams_berightback"];
            delete values["teams_away"];
            delete values["teams_offline"];
            delete values["teams_appearOffline"];
            delete values["teams_available_response"];
            delete values["teams_busy_response"];
            delete values["teams_inacall_response"];
            delete values["teams_presenting_response"];
            delete values["teams_donotdisturb_response"];
            delete values["teams_berightback_response"];
            delete values["teams_away_response"];
            delete values["teams_offline_response"];
            delete values["teams_appearOffline_response"];

            delete values["PresenceDelivery"];
        }

        FormKeys.push({ Id: 'TeamsToPBXStateMapping' });
        FormKeys.push({ Id: 'IsPresenceEnabled' });
        FormKeys.push({ Id: 'EnforceRegionCDRs' });

        FormKeys.forEach(function (obj) {
            var k = obj.Id;

            values['PBXSettings'][k] = values[k];
            delete values[k];
            if (k === 'ToPBXInternationalPrefix'
                || k === 'FromPBXInternationalPrefix'
                || k === 'ToPBXNationalPrefix'
                || k === 'FromPBXNationalPrefix'
            ) {
                // ensure only store e164 rules when localized is used
                // but separate check as localized will allow empty string
                if (!hasLocalE164) {
                    values['PBXSettings'][k] = null;
                }
            } else if (values['PBXSettings'][k] === '') {
                // wipe as normal
                values['PBXSettings'][k] = null;
            }
        });

        if (values['PBXSettings']['ApplyPAIDPrefix'] && values['PBXSettings']['ApplyPAIDPrefix'].includes('checked')) {
            values['PBXSettings']['ApplyPAIDPrefix'] = true;
        } else {
            values['PBXSettings']['ApplyPAIDPrefix'] = false;
        }

        values['PBXSettings']['CallingPolicy'] = null;
        if (values['PBXSettings']['DefaultCallingPolicy'] && values['PBXSettings']['DefaultCallingPolicy'].includes('custom')) {
            var vm = values['PBXSettings']['VMCallingPolicy'];
            var moh = values['PBXSettings']['MOHCallingPolicy'];
            if (vm && moh) {
                values['PBXSettings']['CallingPolicy'] = vm.concat('_', moh);
            }
        }

        // Tidy up unused temp/wrapper values to clean API request body
        delete values['PBXSettings']['VMCallingPolicy'];
        delete values['PBXSettings']['MOHCallingPolicy'];
        delete values['PBXSettings']['DefaultCallingPolicy'];
        delete values['OriginalCallingPolicy'];

        return values;
    }

    justCopiedAction(text) {
        toast.success(text + " copied to clipboard");
    }

    generateSyncPart(values, handleChange) {
        var self = this;

        if (self.props.currentService) {
            if (values && values['ServiceSyncModuleId']) {
                if (!self.props.currentService.SyncEnabled) {
                    return (
                        <>
                            <h5>Sync is available for this PBX service:</h5>
                            <ServiceSyncOnOff
                                updateServicesTrigger={self.props.updateFullServiceTrigger}
                                service={self.props.currentService}
                            />
                            <hr />
                        </>
                    );
                } else {
                    return (
                        <>
                            <h5>Sync is currently enabled for this service:</h5>
                            <ServiceSyncOnOff
                                updateServicesTrigger={self.props.updateFullServiceTrigger}
                                service={self.props.currentService}
                            />
                            <hr />
                        </>
                    );
                }
            }
        } else {
            if (values && values['ServiceSyncModuleId']) {
                return (
                    <>
                        <h5>Sync is available for this PBX service:</h5>
                        <label> <input type="checkbox" name="SyncEnabled" checked={values.SyncEnabled} onChange={handleChange} /> Enable Sync</label>
                        <hr />
                    </>
                );
            }
        }

        return null;
    }

    render() {
        return this.props.render(this);
    }
}
const mapStateToProps = state => {
    const account = state.account;
    const services = state.services;
    return {
        account: account.account,
        baseAccountInfo: account.baseAccountInfo,
        canSelectTrueTransfer: services.canSelectTrueTransfer,
        trueTransferSupported: services.trueTransferSupported
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hasRole: (uiPart = '') => dispatch(actions.hasRole(uiPart)),
        refetchServices: () => dispatch(actions.refetchServices())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PBXTrunkServiceForm);
