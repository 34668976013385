import dayjs from '../dayjs.js';
import DATE_FORMATS from '../constants/dateFormats.js';

export const renderLocalFormattedDate = (utcDateString, format = DATE_FORMATS.FULL_DATETIME) => {

    let formattedDate = formatUtcToLocal(utcDateString, format);

    if (!formattedDate.parsedSuccessfully) {
        return utcDateString;
    }

    return <span title={formattedDate.fromNow}>{formattedDate.formattedLocal}</span>;
};

export const renderUtcFormattedDate = (utcDateString, format = DATE_FORMATS.FULL_DATETIME) => {

    let parsedDate = dayjs.utc(utcDateString);

    if (!parsedDate.isValid()) {
        return utcDateString;
    }

    return <span title={parsedDate.fromNow()}>{parsedDate.format(format)}</span>;
};

export const formatUtcToLocal = (utcDateString, format = DATE_FORMATS.FULL_DATETIME) => {
    let parsedDate = dayjs.utc(utcDateString);

    if (!parsedDate.isValid()) {
        return {
            utcDateString: utcDateString,
            parsedSuccessfully: false
        }
    }

    return {
        utcDateString: utcDateString,
        parsedSuccessfully: true,
        fromNow: parsedDate.fromNow(),
        formattedLocal: parsedDate.local().format(format)
    }
};

export const formatUtcDate = (utcDateString, format) => {
    let parsedDate = dayjs.utc(utcDateString);

    if (!parsedDate.isValid()) {
        return null;
    }

    return parsedDate.format(format);
};